import React, { useState } from 'react';

function ToggleContent() {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div>
      <button onClick={toggleVisibility}>
        {isVisible ? 'Hide Content' : 'Show Content'}
      </button>
      {isVisible && (
        <div>
          <p>This is the hidden content!</p>
        </div>
      )}
    </div>
  );
}

export default ToggleContent;
