import React, { useEffect, useState } from "react";
import { config } from "../config/config";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from '@mui/material/TextField';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Backdrop, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Mic from "@mui/icons-material/Mic";
import MicOff from "@mui/icons-material/MicOff";

import { EnhancedQueryBar } from '../features/queryBar/EnhancedQueryBar';
import { renderQueryComponent } from "../features/queryBar/QueryBarFunctions";
import ErrorBanner from "../features/errorBanner/errorBanner";
import ResetConfirmation from "../features/resetConfirmation/resetConfirmation"
import api from "../config/axiosConfigs";

const textMode = true

function renderTable(field) {

    return (
        <TableContainer >
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {field && field.headers && field.headers.map((header) => (
                            <TableCell><strong>{header}</strong></TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {field && field.rows && field.rows.map((row) => (
                        <TableRow
                            key={`row-${field.label}}`}
                        >
                            {row && row.map((value) => (
                                <TableCell style={{ color: "green", fontWeight: "bold" }}>{value}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                    {(field === undefined || field.rows === undefined || field.rows.length === 0) &&
                        <TableRow key="empyt-row">
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                            <TableCell />
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}


function renderTextField(field, oldField) {
    let this_width = "600px";

    switch (field.size) {
        case 'S':
            this_width = '150px';
            break
        case 'M':
            this_width = '400px';
            break;
        case 'L':
            this_width = '700px';
            break;
        default:
            this_width = '300px';
    }

    let isRequired = true;
    switch (field.optional) {
        case 'false':
            isRequired = true;
            break;
        case 'true':
            isRequired = false;
            break;
        default:
            isRequired = true;
    }

    return (
        <Box
            sx={{
                m: 1,
                fontSize: "0.875rem",
                fontWeight: "700",
                minWidth: this_width,
                flexGrow: 1
            }}>
            <TextField
                required={isRequired}
                id={isRequired ? "outlined-required" : "outlined-helperText"}
                label={field !== undefined && field.label}
                value={field !== undefined && field.value}
                sx={{ minWidth: '100%' }}
                inputProps={{
                    sx: {
                        color: oldField === undefined || oldField.value === undefined || field.value !== oldField.value ? '#43B02A' : 'black',
                        // paddingLeft: '15px',
                        fontSize: '20px',
                        fontWeight: "bold"
                    },
                }}
            />
        </Box>
    )
}

function renderField(field, oldField) {
    switch (field.type) {
        case 'text':
            return renderTextField(field, oldField);
        case 'table':
            return renderTable(field);
        default:
            return renderTextField(field, oldField);
    }
}

function createTitle(title) {
    return <Box display="flex" alignItems="stretch" justifyContent="space-between" gap="10px" paddingLeft="10px" sx={{
        flex: 1,
        m: 1,
        alignItems: 'center'
    }}>
        <Typography title={title} />
        {/* <img src={logo_familienkasse} alt="This is the ZuKo logo" style={{ height: '60px', display: "flex" }} /> */}
    </Box>;
}

function FormFiller({ devmode = false }) {
    // From config, get URL and possible LLM list
    const url = config.API_URL;
    const llms = config.LLMS;

    // Configuration dialog state and config values
    const [llm, setLLM] = useState(0);
    const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false);
    const [selectedLanguageSTT, setSelectedLanguageSTT] = useState('en-GB');

    const [query, setQuery] = useState("");
    const [botResponseText, setBotResponseText] = useState(null);
    const [title, setTitle] = useState("");
    const [formData, setFormData] = useState(null);
    const [formDataPrev, setFormDataPrev] = useState(null);
    const [formStatus, setFormStatus] = useState(null);
    const [readyToSubmit, setReadyToSubmit] = useState(null);
    const [submitText, setSubmitText] = useState("");
    
    // States for displaying different components
    const [isAnswerLoading, setIsAnswerLoading] = useState(false);
    const [error, setError] = useState(null);
    const [resetForm, setResetForm] = useState(false)
  

    //Load the form automatically when the page loads
    useEffect(() => {
        setIsAnswerLoading(true);
        api.get(url + "/formfiller/init", {}).then((response) => {
            setIsAnswerLoading(false);
            console.log("response: ")
            console.log(response)
            if (response.data.form_data !== undefined || response.data.form_data !== 0) {
                const response_data = response.data.form_data;
                setTitle(response_data.title)
                setFormData(response_data.form_data)
                setReadyToSubmit(response_data.ready_to_submit)
                setSubmitText(response_data.submit_text)
                setBotResponseText(response.data.bot_text)
            }
            if (response.data.form_status !== undefined || response.data.form_status !== 0) {
                console.log("Form Status:")
                console.log(response.data.form_status)
                setFormStatus(response.data.form_status)
            }
        }).catch((exeption) => {
        });
    }, []);

    // Handle send new query to chatbot
    const handleQuery = (event, text = transcript) => {
        setIsAnswerLoading(true);
        console.log("handleQuery")
        const data = {
            "query": text,
            "llm": llms[llm].name,
            "display_data": formData,
            "form_status" : formStatus
        }

        setFormDataPrev(formData);
        setQuery("");

        api.post(url + "/formfiller", {
            body: data,
            mode: 'cors'
        }).then((response) => {
            setIsAnswerLoading(false);
            console.log("response: ")
            console.log(response)
            if (response.data.form_data !== undefined || response.data.form_data !== 0) {
                const response_data = response.data.form_data;
                setTitle(response_data.title)
                setFormData(response_data.form_data)
                setReadyToSubmit(response_data.ready_to_submit)
                setSubmitText(response_data.submit_text)
                setBotResponseText(response.data.bot_text)
            }
            if (response.data.form_status !== undefined || response.data.form_status !== 0) {
                console.log("Form Status:")
                console.log(response.data.form_status)
                setFormStatus(response.data.form_status)
            }
        }).catch((exeption) => {
            setIsAnswerLoading(false);
            setError("Error sending request, please retry.");
        });
    };

    const handleStartResetForm = (event) => {
        setResetForm(true)
    }

    const handleCancelResetForm = (event) => {
        setResetForm(false)
    }

    // Handle Reset Form Button
    const handleResetForm = (event) => {
        console.log("Handle Reset Form")
        setIsAnswerLoading(true);

        api.get(url + "/formfiller/resetForm", {}).then((response) => {
            setIsAnswerLoading(false);
            console.log("response: ")
            console.log(response)
            if (response.data.form_data !== undefined || response.data.form_data !== 0) {
                console.log("Found response data")
                const response_data = response.data.form_data;
                setTitle(response_data.title)
                setFormData(response_data.form_data)
                setReadyToSubmit(response_data.ready_to_submit)
                setSubmitText(response_data.submit_text)
                setBotResponseText(response.data.bot_text)
            }
            if (response.data.form_status !== undefined || response.data.form_status !== 0) {
                console.log("Form Status:")
                console.log(response.data.form_status)
                setFormStatus(response.data.form_status)
            }
            setResetForm(false)
        }).catch((exeption) => {
            setIsAnswerLoading(false);
            setError("Error reseting Form");
            setResetForm(false)
        });
    };

    const handleChangeLLM = (event) => {
        setLLM(event.target.value);
    };

    const handleMicOn = (event) => {
        console.log("Mic on");
        console.log(selectedLanguageSTT)
        resetTranscript()
        SpeechRecognition.startListening({ continuous: true, language: selectedLanguageSTT })
    };

    const handleMicOff = (event) => {
        console.log("Mic off");
        SpeechRecognition.stopListening();
        console.log(transcript);
        setQuery(query + transcript)
        resetTranscript()
        console.log(transcript);
    };

    const handleChangeLanguage = (event) => {
        setSelectedLanguageSTT(event.target.value);
      };
    
      const handleOpenSettings = () => {
        SpeechRecognition.stopListening();
        resetTranscript()
        setIsSettingsDialogOpen(true);
      };
    
      const handleCloseSettingsDialog = (event, reason) => {
        if (reason !== 'backdropClick') {
          setIsSettingsDialogOpen(false);
        }
      };
    

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }

    return (
        <Box sx={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            paddingX: '60px',
            paddingY: "60px",
            overflow: 'hidden'
        }}
        >
            {resetForm && <ResetConfirmation handleClickCancelReset={handleCancelResetForm} handleClickConfirmReset={handleResetForm} />}
            {/* Loading Backdrop */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isAnswerLoading}
            >
                <div direction="column">
                <CircularProgress color="inherit" />
                <Typography>Loading...</Typography>
                <Typography>If this takes longer than a minute, please try sending your query again.</Typography>
                </div>
            </Backdrop>
            <div >
                {botResponseText && <Typography variant="h2" color='primary' style={{paddingRight: "40px", paddingLeft: "40px", paddingTop: "15px"}}><strong>Assistant: </strong>{botResponseText}</Typography>}
            </div>
            <Stack spacing={2} style={{ padding: "10px" }} sx={{
                p: 2,
                flexGrow: 1,
                overflow: 'auto',
            }}>
                {/* {readyToSubmit !== undefined && readyToSubmit === true && <Alert sx={{ alignItems: "center" }} icon={<MarkEmailReadIcon fontSize="large" />} severity="success">
                    <AlertTitle sx={{ fontSize: "30px", marginBottom: "0px", marginTop: "0px" }}>{submitText}</AlertTitle>
                </Alert>} */}
                {/* {createTitle(title)} */}
                {formData !== null && formData !== undefined && formData.Sections !== undefined && formData.Sections.map((section, section_index) => (
                    <div style={{ width: "100%" }} sx={{ backgroundColor: 'lightGrey' }}>
                        <Typography style={{ textAlign: "left", color: "black", padding: "10px", margin: "8px" }}><strong>{section.title}</strong></Typography>
                        <Box
                            sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                m: 1,
                                borderRadius: 1,
                                padddingLeft: "0px"
                            }}
                        >
                            {section && section.fields && section.fields.map((field, field_index) => (
                                renderField(field,
                                    (formDataPrev != null && formDataPrev !== undefined && formDataPrev.Sections !== undefined) ? formDataPrev.Sections[section_index].fields[field_index] : undefined)
                            ))}
                        </Box>
                    </div>
                ))}
            </Stack>
            <Stack direction="horizontal" sx={{paddingTop: "5vh", justifyContent: "center", zIndex: (theme) => theme.zIndex.drawer + 2}} className="align-bottom">
                <EnhancedQueryBar
                    query={query} setQuery={setQuery} handleQuery={handleQuery}
                    listening={listening} handleMicOff={handleMicOff} handleMicOn={handleMicOn}
                    transcript={transcript} resetTranscript={resetTranscript}
                    isSettingsDialogOpen={isSettingsDialogOpen} handleOpenSettings={handleOpenSettings} handleCloseSettingsDialog={handleCloseSettingsDialog}
                    handleChangeLanguage={handleChangeLanguage} selectedLanguageSTT={selectedLanguageSTT}
                    llm={llm} handleChangeLLM={handleChangeLLM} handleResetHistory={handleResetForm}
                    enableTagSelection={false}/>
            </Stack>
            {error && <ErrorBanner message={error} errorSetter={setError} />}
        </Box>

    );

}

FormFiller.defaultProps = {
    devmode: false,
}

FormFiller.propTypes = {
    devmode: PropTypes.bool
}

export default FormFiller;