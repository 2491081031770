import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from '@mui/icons-material/Refresh';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Alert, Backdrop, Box, Button, CircularProgress, Divider, Stack, styled, Typography } from '@mui/material';
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import ChatIcon from '@mui/icons-material/Chat';
import { DataGrid } from "@mui/x-data-grid";
import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import api from "../config/axiosConfigs";
import Dropzone from '../features/dropzone/Dropzone';
import './DocumentManagement.css';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import UploadWithAttributes from "../features/attributeManagement/UploadWithAttributes";
import DeleteConfirmation from "../features/deleteConfirmation/DeleteConfirmation";
import ErrorBanner from '../features/errorBanner/errorBanner';
import { PdfViewer } from "../features/PdfViewer";
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import bubble_diagram_example from "../media/BubbleDiagram_Topics.png";


const testDataPolicies = [
  {
      "authorFullName": "Match",
      "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
      "documentTitle": "DORA_1.pdf (100%), DORA_2.pdf (100%)",
      "fullTitle": "ICT_risk_management_tools.pdf",
      "id": "1",
      "tags": [
        "Büromaterial"
      ],
      "timeOfUpload": "Thu, 11 Jan 2024 13:32:31 GMT",
      "detailText": "Intern_1.pdf (100%), Intern_2.pdf (100%)"
  },
  {
      "authorFullName": "Match",
      "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
      "documentTitle": "DORA_1.pdf (100%)",
      "fullTitle": "Übersicht",
      "id": "2",
      "tags": [
        "Büromaterial"
      ],
      "timeOfUpload": "Thu, 11 Jan 2024 13:43:16 GMT",
      "detailText": "Intern_1.pdf (100%)"
  },
  {
      "authorFullName": "Match",
      "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
      "documentTitle": "DORA_1.pdf (100%), DORA_2.pdf (100%)",
      "fullTitle": "Heimatlose Ausländer",
      "id": "3",
      "tags": [
        "Büromaterial",
        "IT"
      ],
      "timeOfUpload": "Thu, 11 Jan 2024 13:44:46 GMT",
      "detailText": "Intern_2.pdf (100%), Intern_3.pdf (100%)"
  },
  {
      "authorFullName": "Match",
      "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
      "documentTitle": "DORA_1.pdf (100%), DORA_2.pdf (50%)",
      "fullTitle": "Seit Geburt Staatenlose",
      "id": "4",
      "tags": [
          "Büromaterial",
          "IT"
      ],
      "timeOfUpload": "Thu, 11 Jan 2024 13:44:56 GMT",
      "detailText": "Intern_2.pdf (100%)"
  },
  {
    "authorFullName": "Match",
    "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
    "documentTitle": "DORA_1.pdf (100%), DORA_2.pdf (50%)",
    "fullTitle": "ICT_risk_management_tools.pdf",
    "id": "5",
    "tags": [
      "Büromaterial"
    ],
    "timeOfUpload": "Thu, 11 Jan 2024 13:32:31 GMT",
    "detailText": "Intern_1.pdf (100%), Intern_2.pdf (100%)"
},
{
    "authorFullName": "Partial Match",
    "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
    "documentTitle": "Office_Equipment.pdf (100%)",
    "fullTitle": "Übersicht",
    "id": "6",
    "tags": [
      "Büromaterial"
    ],
    "timeOfUpload": "Thu, 11 Jan 2024 13:43:16 GMT",
    "detailText": "Office_Düsseldorf.pdf (100%), Office_Hamburg.pdf (100%)"
},
{
    "authorFullName": "Match",
    "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
    "documentTitle": "Office_Equipment.pdf (100%), Ergonomics.pdf (100%)",
    "fullTitle": "Heimatlose Ausländer",
    "id": "7",
    "tags": [
      "Büromaterial",
      "IT"
    ],
    "timeOfUpload": "Thu, 11 Jan 2024 13:44:46 GMT",
    "detailText": "Office_Düsseldorf.pdf (100%), Office_Hamburg.pdf (100%)"
},
{
    "authorFullName": "Match",
    "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
    "documentTitle": "Office_Equipment.pdf (100%), Ergonomics.pdf (100%)",
    "fullTitle": "Seit Geburt Staatenlose",
    "id": "8",
    "tags": [
        "Büromaterial",
        "IT"
    ],
    "timeOfUpload": "Thu, 11 Jan 2024 13:44:56 GMT",
    "detailText": "Office_Düsseldorf.pdf (100%), Office_Hamburg.pdf (100%)"
},
{
  "authorFullName": "No Match",
  "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
  "documentTitle": "Office_Equipment.pdf (100%), Ergonomics.pdf (100%)",
  "fullTitle": "Heimatlose Ausländer",
  "id": "9",
  "tags": [
    "Büromaterial",
    "IT"
  ],
  "timeOfUpload": "Thu, 11 Jan 2024 13:44:46 GMT",
  "detailText": "Office_Düsseldorf.pdf (100%), Office_Hamburg.pdf (100%)"
},
{
  "authorFullName": "Match",
  "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
  "documentTitle": "Ergonomics.pdf (100%)",
  "fullTitle": "Seit Geburt Staatenlose",
  "id": "10",
  "tags": [
      "Büromaterial",
      "IT"
  ],
  "timeOfUpload": "Thu, 11 Jan 2024 13:44:56 GMT",
  "detailText": "Interne Büroausstattungsleitlinien.pdf (100%), Office_Hamburg.pdf (100%)"
},
]

const CustomToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      boxShadow: theme.shadows[1],
      fontSize: 20,
  },
}));

function HistoryDashboard() {
  const maxFileSize = 19;
  const navigate = useNavigate();
  // Content of document list
  const [listContent, setListContent] = useState(null); // needs a duplicate for policy and reference regulation each

  // Interaction status handling
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState(null);
  const [uploadSuccessfull, setUploadSuccessfull] = useState(null);

  // constants for handling the different document Actions
  const [currentItemDelete, setCurrentItemDelete] = useState(); // reuse this for compare button; DELETE: currentItemDelete is set to the item_id and name of the item that is selected for deletion, or to false when none is selected
  const [error, setError] = useState(null);

  useEffect(() => {
    handleClickListDocuments();
  }, []);

  // Handle click to update list of documents
  const handleClickListDocuments = () => {
    setIsUploadLoading(true)
    api.get("/listDocuments").then((response) => {
      let document = response.data;
      setListContent(document)
      setIsUploadLoading(false)
      setUploadMessage(`Updated list.`)
      setUploadSuccessfull("success")
    }).catch((exeption) => {
      console.log(exeption)
      if (exeption.response?.status === 401) {
        navigate('/login', {
          state: {
            error: "Session expired, please login"
          }
        });
      } else {
        setUploadMessage(`Internal Application Error. Please try again later.`)
        setUploadSuccessfull("error")
      }
      setIsUploadLoading(false)
    });
  };

  // Handle click of "Delete" button
  const handleClickDelete = (event, id, name, uuid) => {
    setCurrentItemDelete({
      id: id,
      name: name,
      uuid: uuid
    })

  };

  // Handle click of confirmation in the "delete" confirmation window
  const handleClickConfirmDelete = (event) => {
    setCurrentItemDelete()
    setIsUploadLoading(true)
    console.log(currentItemDelete);
    api.delete(
      "/deleteItem/" + "uuid=" + currentItemDelete.uuid
    ).then((response) => {
      console.log("Delete call response:")
      console.log(response.data)
      setIsUploadLoading(false)
      setUploadMessage(`Successfully deleted file ${currentItemDelete.name}`)
      setUploadSuccessfull("success")
      handleClickListDocuments();
    }).catch((exeption) => {
      console.log(exeption)
      setUploadMessage(`Internal Application Error. Please try again later.`)
      setUploadSuccessfull("error")
      setIsUploadLoading(false)
    });
  };

  const handleClickCancelDelete = (e) => {
    setCurrentItemDelete()
  }


  // Definition of document management table structure
  const columns = [
    {
      field: "id",
      renderHeader: () => (
        <strong>
          {'ID'}
        </strong>
      ),
      width: 50
    },
    {
      field: "timeOfUpload",
      renderHeader: () => (
        <strong>
          {"Start of Comparison"}
        </strong>
      ),
      type: "string",
      headerAlign: "center",
      align: "center",
      width: 200
    },
    {
      field: "documentTitle",
      renderHeader: () => (
        <strong>
          {"Selected Requirements"}
        </strong>
      ),
      type: "string",
      headerAlign: "center",
      align: "left",
      width: 300
    },
    {
      field: "detailText",
      renderHeader: () => (
        <strong>
          {'Selected Policies'}
        </strong>
      ),
      headerAlign: "center",
      width: 300
    },
    // {
    //   field: "authorFullName",
    //   renderHeader: () => (
    //     <strong>
    //       {'Status'} 
    //     </strong>
    //   ),
    //   type: "string",
    //   headerAlign: "center",
    //   align: "center"
    // },
    {
      field: "actions",
      renderHeader: () => (
        <strong>
          {'Actions'}
        </strong>
      ),
      editable: true,
      minWidth: 140,
      renderCell: (params) => {
        const item_id = params.row?.id
        const item_name = params.row?.documentTitle
        const item_uuid = params.row?.uuid
        return (
          <div>
            <CustomToolTip title="Delete" placement="bottom">
              <IconButton size="100px" onClick={(e) => handleClickDelete(e, item_id, item_name, item_uuid)}>
                <DeleteIcon />
              </IconButton>
            </CustomToolTip>
            {/* <IconButton>
              <DownloadIcon />
            </IconButton> */}
            <CustomToolTip title="View" placement="bottom">
              <IconButton >
                <VisibilityIcon />
              </IconButton>
            </CustomToolTip>
            {/* <CustomToolTip title="Edit" placement="bottom">
              <IconButton>
                <EditIcon onClick={(e) => handleClickEdit(params.row)} />
              </IconButton>            
            </CustomToolTip> */}
          </div>
        );
      },
      headerAlign: "center",
      align: "center"
    }
  ];

  const handleClickChatbot = () => {
    console.log("Now we should switch to the Chatbot page.")
  }

  return (
    <div>

      {/* Backdrop when document upload process is ongoing */}
      <Backdrop
        className="Doc-Management-Backdrop"
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isUploadLoading}
      >
        <div direction="column">
          <CircularProgress color="inherit" />
          <Typography>Loading</Typography>
        </div>
      </Backdrop>

      {/* Page Content */}
      <Stack
        className="Doc-Management-Stack-Row"
        direction="row"
        spacing={1}>

        {/* Left Side Mock-Up: Overview */}
        <Box className="detailViewUpload" sx={{ width: '60vb' }}>
          <Stack>
            <Paper sx={{ width: '100%', height: '745px', marginBottom: '20px'}}>
              <Typography variant="h6">
                <b>Document Overview</b><br/>
              </Typography>
              
              {/* Show Information on Documents */}
              <Box marginLeft="10px" marginRight="10px">
              <Typography align="center" variant="body2">
                  <b>Number of Uploaded Documents: </b> 241 <br />
                  <br/><br/>
                </Typography>
                <Typography variant="h6">
                  <b>Topics</b>
                </Typography>
                <div>
                  <img src={bubble_diagram_example} alt="An example for a bubble diagram showing topic distribution among the uploaded documents." style={{ height: '300px' }} />
                </div>            
              </Box>
            </Paper>

          </Stack>
        </Box>
        
        {/* Right Side: List of Past Comparisons */}
        <Stack
          spacing={1} >
            <Typography variant="h6">
              <b>Past Comparisons</b>
            </Typography>
            <Box
              className="Doc-Management-Box-Table" width="1000px" height="700px">
              <DataGrid
                rows={testDataPolicies ? (testDataPolicies) : ({})} // if no listContent was loaded yet: display "No rows"
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 }
                  }
                }}
                pageSizeOptions={[10]}
              />
            </Box>
        </Stack>
        

        

        {/* </Box> */}
        <div style={{ position: "absolute", left: "50%" }}>
          {/* Delete Confirmation Dialogue */}
          {currentItemDelete && <DeleteConfirmation handleClickCancelDelete={handleClickCancelDelete} handleClickConfirmDelete={handleClickConfirmDelete} currentItemDelete={currentItemDelete} confirmationInformation={"Selected file: " + currentItemDelete.name} />}
        </div>
        {error && <ErrorBanner message={error} errorSetter={setError} />}
      </Stack>
    </div>
  )
}

export default HistoryDashboard;