import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Alert, Backdrop, Box, Button, CircularProgress, Divider, Stack, styled, Typography } from '@mui/material';
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PendingIcon from '@mui/icons-material/Pending';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckIcon from '@mui/icons-material/Check';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { DataGrid } from "@mui/x-data-grid";
import CancelIcon from '@mui/icons-material/Cancel';
import ClearIcon from '@mui/icons-material/Clear';
import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import api from "../config/axiosConfigs";
import Dropzone from '../features/dropzone/Dropzone';
import './DocumentManagement.css';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import UploadWithAttributes from "../features/attributeManagement/UploadWithAttributes";
import DeleteConfirmation from "../features/deleteConfirmation/DeleteConfirmation";
import ErrorBanner from '../features/errorBanner/errorBanner';
import { PdfViewer } from "../features/PdfViewer";
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '50%' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const testDataPolicies = [
  {
      "authorFullName": "Policy",
      "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
      "documentTitle": "Intern_1.pdf",
      "fullTitle": "ICT_risk_management_tools.pdf",
      "id": 2,
      "tags": [
        "Büromaterial"
      ],
      "timeOfUpload": "Thu, 11 Jan 2024 13:32:31 GMT",
      "detailText": "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."
  },
  {
      "authorFullName": "Policy",
      "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
      "documentTitle": "Intern_2.pdf",
      "fullTitle": "Übersicht",
      "id": 3,
      "tags": [
        "Büromaterial"
      ],
      "timeOfUpload": "Thu, 11 Jan 2024 13:43:16 GMT",
      "detailText": "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."
  },
  {
      "authorFullName": "Policy",
      "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
      "documentTitle": "Intern_3.pdf",
      "fullTitle": "Heimatlose Ausländer",
      "id": 7,
      "tags": [
        "Büromaterial",
        "IT"
      ],
      "timeOfUpload": "Thu, 11 Jan 2024 13:44:46 GMT",
      "detailText": "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."
  },
  {
      "authorFullName": "Policy ",
      "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
      "documentTitle": "Intern_4.pdf",
      "fullTitle": "Seit Geburt Staatenlose",
      "id": 8,
      "tags": [
          "Büromaterial",
          "IT"
      ],
      "timeOfUpload": "Thu, 11 Jan 2024 13:44:56 GMT",
      "detailText": "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."
  }
]

const testDataRegulations = [
  {
      "authorFullName": "Reference",
      "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
      "documentTitle": "DORA_2.pdf",
      "fullTitle": "bsi_standard_200.pdf",
      "id": 1,
      "tags": [
        "IT"
      ],
      "timeOfUpload": "Thu, 11 Jan 2024 13:31:16 GMT",
      "detailText": "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."
  },
  {
      "authorFullName": "Reference",
      "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
      "documentTitle": "DORA_3.pdf",
      "fullTitle": "Migration und Asyl",
      "id": 4,
      "tags": [
        "Büromaterial"
      ],
      "timeOfUpload": "Thu, 11 Jan 2024 13:43:34 GMT",
      "detailText": "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."
  },
  {
      "authorFullName": "Reference",
      "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
      "documentTitle": "DORA_3.pdf",
      "fullTitle": "Ehegatten",
      "id": 5,
      "tags": [
          "IT"
      ],
      "timeOfUpload": "Thu, 11 Jan 2024 13:43:59 GMT",
      "detailText": "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."
  },
  {
      "authorFullName": "Reference",
      "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
      "documentTitle": "DORA_4.pdf",
      "fullTitle": "Ausländer mit Einbürgerungsanspruch",
      "id": 6,
      "tags": [
        "IT"
      ],
      "timeOfUpload": "Thu, 11 Jan 2024 13:44:33 GMT",
      "detailText": "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."
  },
]

const CustomToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      boxShadow: theme.shadows[1],
      fontSize: 20,
  },
}));

function ProgressComparison() {
  const maxFileSize = 19;
  const navigate = useNavigate();
  // Content of document list
  const [listContent, setListContent] = useState(null); // needs a duplicate for policy and reference regulation each

  // Interaction status handling
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState(null);
  const [uploadSuccessfull, setUploadSuccessfull] = useState(null);

  // constants for handling the different document Actions
  const [currentItemDelete, setCurrentItemDelete] = useState(); // reuse this for compare button; DELETE: currentItemDelete is set to the item_id and name of the item that is selected for deletion, or to false when none is selected
  const [error, setError] = useState(null);

  // Progress Bar
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    handleClickListDocuments();
  }, []);

  // Handle click to update list of documents
  const handleClickListDocuments = () => {
    setIsUploadLoading(true)
    api.get("/listDocuments").then((response) => {
      let document = response.data;
      setListContent(document)
      setIsUploadLoading(false)
      setUploadMessage(`Updated list.`)
      setUploadSuccessfull("success")
    }).catch((exeption) => {
      console.log(exeption)
      if (exeption.response?.status === 401) {
        navigate('/login', {
          state: {
            error: "Session expired, please login"
          }
        });
      } else {
        setUploadMessage(`Internal Application Error. Please try again later.`)
        setUploadSuccessfull("error")
      }
      setIsUploadLoading(false)
    });
  };

  // Handle click of "Delete" button
  const handleClickDelete = (event, id, name, uuid) => {
    setCurrentItemDelete({
      id: id,
      name: name,
      uuid: uuid
    })

  };

  // Handle click of confirmation in the "delete" confirmation window
  const handleClickConfirmDelete = (event) => {
    setCurrentItemDelete()
    setIsUploadLoading(true)
    console.log(currentItemDelete);
    api.delete(
      "/deleteItem/" + "uuid=" + currentItemDelete.uuid
    ).then((response) => {
      console.log("Delete call response:")
      console.log(response.data)
      setIsUploadLoading(false)
      setUploadMessage(`Successfully deleted file ${currentItemDelete.name}`)
      setUploadSuccessfull("success")
      handleClickListDocuments();
    }).catch((exeption) => {
      console.log(exeption)
      setUploadMessage(`Internal Application Error. Please try again later.`)
      setUploadSuccessfull("error")
      setIsUploadLoading(false)
    });
  };

  const handleClickCancelDelete = (e) => {
    setCurrentItemDelete()
  }


  // Definition of document management table structure
  const columns = [
    {
      field: "id",
      renderHeader: () => (
        <strong>
          {'ID'}
        </strong>
      ),
    },
    {
      field: "documentTitle",
      renderHeader: () => (
        <strong>
          {'Document Name'}
        </strong>
      ),
      width: 150
    },
    {
      field: "authorFullName",
      renderHeader: () => (
        <strong>
          {'Type'}
        </strong>
      ),
      type: "string",
      headerAlign: "center",
      align: "center"
    },
    {
      field: "detailText",
      renderHeader: () => (
        <strong>
          {"Text"}
        </strong>
      ),
      type: "string",
      headerAlign: "center",
      align: "center",
      width: 300
    },
  ];

  return (
    <div>
      {/* Backdrop when document upload process is ongoing */}
      <Backdrop
        className="Doc-Management-Backdrop"
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={ isUploadLoading }
      >
        <div direction="column">
          <CircularProgress color="inherit" />
          <Typography>Loading</Typography>
        </div>
      </Backdrop>

      {/* Page Content */}
      <Box className="comparison-page">
        <Stack>
          <Box marginTop="100px" width="100%" display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h1">
              <b>Comparison in progress...</b>
            </Typography>
            <LinearProgressWithLabel value={progress} />
          </Box>
          
          <Stack
            className="progress-comparison-stack-row"
            direction="row"
            spacing={1}
            marginLeft='50px'
            marginRight='50px' >
            
            {/* Left Side: List of regulations that are being compared*/}
            <Box sx={{ width: '100%' }}>
              <Stack
                className="Doc-Management-Stack-Column"
                direction="column"
                spacing={2}
              >
                <Paper sx={{ width: '100%', height: '700px', marginBottom: '20px'}}>
                  <Typography variant="h6">
                    <b>Selected Regulations</b>
                  </Typography>
                  <Box sx={{ margin: '20px' }}>
                    <Box marginBottom="40px">
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<CheckIcon />}
                        >
                          <Typography><b>DORA_2.pdf | </b> 2 (a)</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography align="left">
                            <b>Source Document: </b> DORA_2.pdf <br />
                            <b>ID within Document: </b> 2 (a) <br />
                            <b>Page: </b> 3 <br/>
                            <b>Lines: </b> 13 - 25 <br/>
                            <b>Full Text: </b><br/>
                            At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<NewReleasesIcon />}
                        >
                          <Typography><b>DORA_2.pdf | </b> 2 (b)</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography align="left">
                            <b>Source Document: </b> DORA_2.pdf <br />
                            <b>ID within Document: </b> 2 (b) <br />
                            <b>Page: </b> 3 <br/>
                            <b>Lines: </b> 13 - 25 <br/>
                            <b>Full Text: </b><br/>
                            At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion>
                        <AccordionSummary
                          expandIcon={<PendingIcon />}
                        >
                          <Typography><b>DORA_2.pdf | </b> 2 (c)</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography align="left">
                            <b>Source Document: </b> DORA_2.pdf <br />
                            <b>ID within Document: </b> 2 (c) <br />
                            <b>Page: </b> 3 <br/>
                            <b>Lines: </b> 13 - 25 <br/>
                            <b>Full Text: </b><br/>
                            At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion>
                        <AccordionSummary
                          expandIcon={<PendingActionsIcon />}
                        >
                          <Typography><b>DORA_2.pdf | </b> 2 (d)</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography align="left">
                            <b>Source Document: </b> DORA_2.pdf <br />
                            <b>ID within Document: </b> 2 (d) <br />
                            <b>Page: </b> 3 <br/>
                            <b>Lines: </b> 13 - 25 <br/>
                            <b>Full Text: </b><br/>
                            At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion>
                        <AccordionSummary
                          expandIcon={<PendingActionsIcon />}
                        >
                          <Typography><b>DORA_3.pdf | </b> 3 (a)</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography align="left">
                            <b>Source Document: </b> DORA_3.pdf <br />
                            <b>ID within Document: </b> 3 (a) <br />
                            <b>Page: </b> 3 <br/>
                            <b>Lines: </b> 13 - 25 <br/>
                            <b>Full Text: </b><br/>
                            At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  </Box>              
                </Paper>
                
              </Stack>
              {/* </div> */}
            </Box>

            {/* Mock-Up: Progress of comparing selected Regulation to selected policies */}
            <Box className="detailViewUpload" sx={{ width: '100vb' }} >
              <Stack>
                <Paper sx={{ width: '100%', height: '700px', marginBottom: '20px', paddingX: '10px'}}>
                  <Typography variant="h6">
                    <b>Processed Policies</b><br/>
                  </Typography>
                  <Typography variant="body1" align="left">
                  <br/>Here you can see all policies that are currently analyzed for matches with the selected regulations, and the current status of their comparison. <br/><br/>
                  {/* Un-comment the following for no regulation selected: */}
                    {/* Please select a regulation on the left to see its progress. */} 
                  <b>Selected regulation:</b> DORA_2.pdf | 2 (c) <br/>
                  <b>Status:</b> Processing...
                  </Typography>
                  <Box sx={{ margin: '20px', marginBottom: '60px' }}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<CheckIcon />}
                      >
                        <Typography><b>Intern_1.pdf | </b> <i>Policy ID: </i>1</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography align="left">
                          <b>Source Document: </b> DORA_2.pdf <br />
                          <b>ID within Document: </b> 2 (a) <br />
                          <b>Page: </b> 3 <br/>
                          <b>Lines: </b> 13 - 25 <br/>
                          <b>Full Text: </b><br/>
                          At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ClearIcon />}
                      >
                        <Typography><b>Intern_1.pdf | </b> <i>Policy ID: </i>2</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography align="left">
                          <b>Source Document: </b> DORA_2.pdf <br />
                          <b>ID within Document: </b> 2 (c) <br />
                          <b>Page: </b> 3 <br/>
                          <b>Lines: </b> 13 - 25 <br/>
                          <b>Full Text: </b><br/>
                          At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<CheckIcon />}
                      >
                        <Typography><b>Intern_1.pdf | </b> <i>Policy ID: </i>3</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography align="left">
                          <b>Source Document: </b> DORA_2.pdf <br />
                          <b>ID within Document: </b> 2 (a) <br />
                          <b>Page: </b> 3 <br/>
                          <b>Lines: </b> 13 - 25 <br/>
                          <b>Full Text: </b><br/>
                          At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ClearIcon />}
                      >
                        <Typography><b>Intern_1.pdf | </b> <i>Policy ID: </i>4</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography align="left">
                          <b>Source Document: </b> DORA_2.pdf <br />
                          <b>ID within Document: </b> 2 (c) <br />
                          <b>Page: </b> 3 <br/>
                          <b>Lines: </b> 13 - 25 <br/>
                          <b>Full Text: </b><br/>
                          At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ClearIcon />}
                      >
                        <Typography><b>Intern_2.pdf | </b> <i>Policy ID: </i>1</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography align="left">
                          <b>Source Document: </b> DORA_2.pdf <br />
                          <b>ID within Document: </b> 2 (c) <br />
                          <b>Page: </b> 3 <br/>
                          <b>Lines: </b> 13 - 25 <br/>
                          <b>Full Text: </b><br/>
                          At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<PendingIcon />}
                      >
                        <Typography><b>Intern_2.pdf | </b> <i>Policy ID: </i>2</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography align="left">
                          <b>Source Document: </b> DORA_2.pdf <br />
                          <b>ID within Document: </b> 2 (c) <br />
                          <b>Page: </b> 3 <br/>
                          <b>Lines: </b> 13 - 25 <br/>
                          <b>Full Text: </b><br/>
                          At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<PendingActionsIcon />}
                      >
                        <Typography><b>Intern_3.pdf | </b> <i>Policy ID: </i>1</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography align="left">
                          <b>Source Document: </b> DORA_2.pdf <br />
                          <b>ID within Document: </b> 2 (d) <br />
                          <b>Page: </b> 3 <br/>
                          <b>Lines: </b> 13 - 25 <br/>
                          <b>Full Text: </b><br/>
                          At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<PendingActionsIcon />}
                      >
                        <Typography><b>Intern_3.pdf | </b> <i>Policy ID: </i>2</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography align="left">
                          <b>Source Document: </b> DORA_3.pdf <br />
                          <b>ID within Document: </b> 3 (a) <br />
                          <b>Page: </b> 3 <br/>
                          <b>Lines: </b> 13 - 25 <br/>
                          <b>Full Text: </b><br/>
                          At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </Box>              
                </Paper>
                
                <Stack
                  className="Doc-Comparison-Buttons-Stack-Row"
                  direction="row"
                  spacing={1}>
                  {/* <Button
                    className="Doc-Management-Button-Update"
                    sx={{
                      iconSizeMedium: { "& > *:first-child": { fontSize: 22 } }
                    }}
                    variant="outlined"
                    startIcon={<RefreshIcon />}
                    onClick={(e) => handleClickListDocuments(e)} >
                    Update Tables
                  </Button> */}

                  <Box sx={{display: 'flex', justifyContent: 'right'}}>
                    <Button
                      className="Doc-Management-Button-Update"
                      sx={{
                        iconSizeMedium: { "& > *:first-child": { fontSize: 22 } }
                      }}
                      variant="outlined"
                      startIcon={<CancelIcon />}
                      onClick={(e) => handleClickListDocuments(e)} >
                      Cancel comparison
                    </Button>
                  </Box>
                </Stack>

                
              </Stack>
            </Box>


            {/* </Box> */}
            <div style={{ position: "absolute", left: "50%" }}>
              {/* Delete Confirmation Dialogue */}
              {currentItemDelete && <DeleteConfirmation handleClickCancelDelete={handleClickCancelDelete} handleClickConfirmDelete={handleClickConfirmDelete} currentItemDelete={currentItemDelete} confirmationInformation={"Selected file: " + currentItemDelete.name} />}
            </div>
            {error && <ErrorBanner message={error} errorSetter={setError} />}
          </Stack>
        </Stack>        
      </Box>
      
    </div>

  )
}

export default ProgressComparison;
