import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Alert, Backdrop, Box, Button, CircularProgress, Divider, Stack, styled, Typography } from '@mui/material';
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import ChatIcon from '@mui/icons-material/Chat';
import React, { useCallback, useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import api from "../config/axiosConfigs";
import Dropzone from '../features/dropzone/Dropzone';
import './DocumentManagement.css';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import UploadWithAttributes from "../features/attributeManagement/UploadWithAttributes";
import DeleteConfirmation from "../features/deleteConfirmation/DeleteConfirmation";
import ErrorBanner from '../features/errorBanner/errorBanner';
import { PdfViewer } from "../features/PdfViewer";
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { PieChart } from '@mui/x-charts/PieChart';

import Boxplot from "../features/boxplot/Boxplot";
import BoxPlotComponent from "../features/boxplot/Boxplot2";




const testdata_piechart = [
  { id: 0, value: 2, label: 'Match' },
  { id: 1, value: 2, label: 'Partial Match' },
  { id: 2, value: 1, label: 'No Match' },
];


const testdata_boxplot= {
  'Match': [10.7577, 19.9273, 15.1234, 18.5678, 16.789, 17.2345, 13.4567, 14.5678, 11.2345, 12.3456],
  'Partial Match' : [13.8917, 0.5102, 12.3456, 14.7891, 11.2345, 15.5678, 13.1234, 12.5678, 16.3456, 17.4567],
  'No Match': [10.5524, 9.8765, 11.3456, 10.789, 12.3456, 13.2345, 14.3456, 15.4567, 16.5678, 17.6789]
}


const reportData = [
  {
    requirement: { title: "Serious Incident: Providing Documentation", content: "Manufacturers shall provide [the competent authority] all documents necessary for the risk assessment." },
    status: { title: "Partial Match", color: "#efcc00" },
    policy: ["SOP_FCA_5.3.1", "SOP_FCA_5.3.3"],
  },
  {
    requirement: { title: "Serious Incident: Co-operation", content: "The manufacturer shall co-operate with the competent authorities and where relevant with the notified body concerned during the investigations referred to in EUMDR17-A89-1a." },
    status: { title: "Match", color: "#43B02A" },
    policy: ["SOP_FCA_5.4.1", "SOP_FCA_5.4.2"],
  },
  {
    requirement: { title: "FCA: Field Safety Notice", content: "The manufacturer shall ensure that information about the field safety corrective action taken is brought without delay to the attention of users of the device in question by means of a field safety notice." },
    status: { title: "Match", color: "#43B02A" },
    policy: ["SOP_FCA_5.3.1"],
  },
  {
    requirement: { title: "FCA: FSN Language", content: "The field safety notice shall be edited in an official Union language or languages determined by the Member State in which the field safety corrective action is taken. "},
    status: { title: "Partial Match", color: "#efcc00" },
    policy: ["SOP_FCA_5.3.1"],
  },
  {
    requirement: { title: "FCA: FSN Draft Submission", content: "The content of the draft field safety notice shall be submitted to the evaluating competent authority or, in the cases referred to in paragraph 9, to the coordinating competent authority to allow it to make comments." },
    status: { title: "No Match", color: "red" },
    policy: [],
  },
];

const CustomToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      boxShadow: theme.shadows[1],
      fontSize: 20,
  },
}));

function ComparisonDashboard() {
  const maxFileSize = 19;
  const navigate = useNavigate();
  // Content of document list
  const [listContent, setListContent] = useState(null); // needs a duplicate for policy and reference regulation each

  // Interaction status handling
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState(null);
  const [uploadSuccessfull, setUploadSuccessfull] = useState(null);

  // constants for handling the different document Actions
  const [currentItemDelete, setCurrentItemDelete] = useState(); // reuse this for compare button; DELETE: currentItemDelete is set to the item_id and name of the item that is selected for deletion, or to false when none is selected
  const [error, setError] = useState(null);

  useEffect(() => {
    handleClickListDocuments();
  }, []);

  // Handle click to update list of documents
  const handleClickListDocuments = () => {
    setIsUploadLoading(true)
    api.get("/listDocuments").then((response) => {
      let document = response.data;
      setListContent(document)
      setIsUploadLoading(false)
      setUploadMessage(`Updated list.`)
      setUploadSuccessfull("success")
    }).catch((exeption) => {
      console.log(exeption)
      if (exeption.response?.status === 401) {
        navigate('/login', {
          state: {
            error: "Session expired, please login"
          }
        });
      } else {
        setUploadMessage(`Internal Application Error. Please try again later.`)
        setUploadSuccessfull("error")
      }
      setIsUploadLoading(false)
    });
  };

  // Handle click of "Delete" button
  const handleClickDelete = (event, id, name, uuid) => {
    setCurrentItemDelete({
      id: id,
      name: name,
      uuid: uuid
    })

  };

  // Handle click of confirmation in the "delete" confirmation window
  const handleClickConfirmDelete = (event) => {
    setCurrentItemDelete()
    setIsUploadLoading(true)
    console.log(currentItemDelete);
    api.delete(
      "/deleteItem/" + "uuid=" + currentItemDelete.uuid
    ).then((response) => {
      console.log("Delete call response:")
      console.log(response.data)
      setIsUploadLoading(false)
      setUploadMessage(`Successfully deleted file ${currentItemDelete.name}`)
      setUploadSuccessfull("success")
      handleClickListDocuments();
    }).catch((exeption) => {
      console.log(exeption)
      setUploadMessage(`Internal Application Error. Please try again later.`)
      setUploadSuccessfull("error")
      setIsUploadLoading(false)
    });
  };

  const handleClickCancelDelete = (e) => {
    setCurrentItemDelete()
  }


  // Definition of document management table structure
  const columns = [
    {
      field: "id",
      renderHeader: () => (
        <strong>
          {'ID'}
        </strong>
      ),
    },
    {
      field: "documentTitle",
      renderHeader: () => (
        <strong>
          {'Document Name'}
        </strong>
      ),
      width: 150
    },
    {
      field: "authorFullName",
      renderHeader: () => (
        <strong>
          {'Type'}
        </strong>
      ),
      type: "string",
      headerAlign: "center",
      align: "center"
    },
    {
      field: "detailText",
      renderHeader: () => (
        <strong>
          {"Text"}
        </strong>
      ),
      type: "string",
      headerAlign: "center",
      align: "center",
      width: 300
    },
  ];
  const navigateToEvalReportChat = () => {
    // Navigate to the other page
    navigate('/evaluationReportFromChat');
  };

  const handleClickChatbot = () => {
    console.log("Now we should switch to the Chatbot page.")
    navigate('/chat');
  };


  return (
    
    <div>
       
      {/* Backdrop when document upload process is ongoing */}
      <Backdrop
        className="Doc-Management-Backdrop"
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isUploadLoading}
      >
        <div direction="column">
          <CircularProgress color="inherit" />
          <Typography>Loading</Typography>
        </div>
      </Backdrop>

      {/* Page Content */}
      <Box className="page-content" display="flex" alignItems="center" width="90%" paddingLeft="120px" alignSelf="center">
        <Stack
          className="Doc-Management-Stack-Row"
          direction="row"
          spacing={1}
          align="center">
          
          {/* Left Side: High-Level Reporting */}
          <Box >
            <Stack
              className="Doc-Management-Stack-Column"
              direction="column"
              spacing={2}
              width="620px"
              marginX={'10px'}
            >
              {/* High-Level Report */}
              <Paper sx={{ width: '90%' }}>
                <Box marginX={'10px'}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    <b>High-Level Report</b>
                  </Typography>
                  <Typography align="left" sx={{ mb: 2 }}>
                    <b>Analyzed Documents: </b>SOP_Field_Corrective_Actions.pdf <br />
                    <b>Reference Documents: </b>MDR_ REGULATION (EU) 2017_745.pdf​ <br />
                    <b>Date of Comparison: </b>2024-05-15 <br/>
                    <b>Topic: </b> Field Safety Notice <br/>
                  </Typography>
                </Box>
              </Paper>
              <Stack direction="row" spacing={1} sx={{ width: '90%'}}>
                <Paper sx={{ width: '100%' }}>
                  <Typography variant="h6" align="center">
                    <b>Pie Chart</b>
                  </Typography>
                  <PieChart
                    series={[
                      {
                        data: testdata_piechart,
                        highlightScope: { faded: 'global', highlighted: 'item' },
                        faded: { innerRadius: 20, additionalRadius: -30, color: 'gray' },
                      },
                    ]}
                    height={160}
                    colors={['#43B02A', '#efcc00', 'red']} 
                    // colors={['#86BC25', '#0097A9', '#75787B']}
                  />
                </Paper>
                {/* <Paper sx={{ width: '60%'}}>
                  <Typography variant="h6" align="center">
                    <b>Box Plot</b>
                  </Typography>
                  <BoxPlotComponent
                    data = {testdata_boxplot}
                  />
                </Paper>      */}
              </Stack>
              
              <Paper sx={{ width: '90%', height: '300px', justifyContent: 'center' }}>
                <Box marginTop="100px">
                  <Typography variant="h6">
                    <b>Chatbot</b>
                  </Typography>
                  <Typography >
                    Do you want to interact with the results via a chatbot?
                  </Typography>
                  <CustomToolTip title="Chatbot" placement="bottom">
                    <IconButton>
                      <ChatIcon onClick={(e) => handleClickChatbot()} />
                    </IconButton>            
                  </CustomToolTip>
                </Box>
                
              </Paper>
              
            </Stack>
            {/* </div> */}
          </Box>

          {/* List of Analyzed Regulatory Requirements with Matches */}
          <Box className="detailViewUpload" sx={{ width: '90%' }}>
            <Stack>
              <Paper className="PaperRight" sx={{ width: '100%', height: '620px', marginBottom: '20px'}}>
                <Typography variant="h6">
                  <b>List of Analyzed Regulatory Requirements with Matches</b>
                </Typography>

                {/* Iterating over reportData */}
                {reportData.map((item, index) => (
                  <Stack className="single-row" key={index} direction="row" spacing={3} sx={{ width: '100%', marginTop: '20px',  textAlign: 'left'}}>
                    {/* Render Box for Requirement */}
                    <Box marginBottom="40px" sx={{ flex: 3 , height:'100%'}}>
                      <Accordion>
                        <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                          <Typography>{item.requirement.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>{item.requirement.content}</Typography>
                        </AccordionDetails>
                      </Accordion>
                    </Box>

                    {/* Render Box for Status */}
                    <Box marginBottom="40px" width="100px" sx={{ flex: 1 , height:'100%', width:'10px'} }>
                      <Paper sx={{ padding: '13px' }}>
                        <Typography sx={{ color: item.status.color }}><b>{item.status.title || "No status available"}</b></Typography>
                      </Paper>
                    </Box>

                    {/* Render Box for Policies */}
                    <Box marginBottom="40px" sx={{ flex: 2 , height:'100%'}}>
                      {item.policy.length === 1 ? (
                        <Paper sx={{ padding: '13px' }}>
                          <Typography sx={{ textAlign: 'left' }}>{item.policy[0]}</Typography>
                        </Paper>
                      ) : (
                        item.policy.length > 1 ? (
                          <Accordion>
                            {item.policy.map((policy, idx) => (
                              <AccordionSummary key={idx} expandIcon={<ArrowDropDownIcon />}>
                                <Typography>{policy}</Typography>
                              </AccordionSummary>
                            ))}
                          </Accordion>
                        ) : (
                          <Paper sx={{ padding: '13px' }}>
                            <Typography sx={{ textAlign: 'left' }}>No policies available</Typography>
                          </Paper>
                        )
                      )}
                    </Box>
                  </Stack>
                  ))}
                </Paper>

                {/* Go to evaluation page */}
                <Stack
                  className="Doc-Comparison-Buttons-Stack-Row"
                  direction="row"
                  spacing={1}>

                  <Box sx={{display: 'flex', justifyContent: 'right'}}>
                    <Button
                      className="Doc-Management-Button-Update"
                      sx={{
                        iconSizeMedium: { "& > *:first-child": { fontSize: 22 } },
                        backgroundColor: '#43B02A', 
                        color:'white',
                        "&:hover": {
                            // change the color when the button is hovered
                            backgroundColor: 'white',
                            color:'#43B02A',
                        }
                    }}
                      variant="outlined"
                      startIcon={<RefreshIcon />}
                      // onClick={(e) => handleClickListDocuments(e)} >
                      onClick ={navigateToEvalReportChat}  >
                      Go to Evaluation Report
                    </Button>
                  </Box>
                </Stack>


              </Stack>
            </Box>
                  
          <div style={{ position: "absolute", left: "50%" }}>
            {/* Delete Confirmation Dialogue */}
            {currentItemDelete && <DeleteConfirmation handleClickCancelDelete={handleClickCancelDelete} handleClickConfirmDelete={handleClickConfirmDelete} currentItemDelete={currentItemDelete} confirmationInformation={"Selected file: " + currentItemDelete.name} />}
          </div>
          {error && <ErrorBanner message={error} errorSetter={setError} />}
        </Stack>
      </Box>
      
    </div>
  )
}

export default ComparisonDashboard;