import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Alert, Backdrop, Box, Button, CircularProgress, Divider, Stack, styled, Typography } from '@mui/material';
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import { DataGrid } from "@mui/x-data-grid";
import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import api from "../config/axiosConfigs";
import Dropzone from '../features/dropzone/Dropzone';
import './DocumentManagement.css';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import UploadWithAttributes from "../features/attributeManagement/UploadWithAttributes";
import DeleteConfirmation from "../features/deleteConfirmation/DeleteConfirmation";
import ErrorBanner from '../features/errorBanner/errorBanner';
import { PdfViewer } from "../features/PdfViewer";
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InitComparison from "./InitComparison";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};


const CustomToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      boxShadow: theme.shadows[1],
      fontSize: 20,
  },
}));

const testDataCompliance = [              
      {
          "authorFullName": "Policy",
          "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
          "documentTitle": "SOP_Field_Corrective_Actions.pdf",
          "fullTitle": "ICT_risk_management_tools.pdf",
          "id": 1,
          "tags": [
            "FCA",
            "Assessment",
            "Execution"
          ],
          "timeOfUpload": "Tue, 15 May 2024 13:32:31 GMT"
      },
      {
          "authorFullName": "Policy",
          "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
          "documentTitle": "SOP02_Field_Corrective_Actions.pdf",
          "fullTitle": "Übersicht",
          "id": 2,
          "tags": [
            "FCA",
            "Assessment",
            "Execution"
          ],
          "timeOfUpload": "Tue, 15 May 2024 13:43:16 GMT"
      },
      {
          "authorFullName": "Policy",
          "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
          "documentTitle": "SOP_Corrective_and_Preventive_Action.pdf",
          "fullTitle": "Migration und Asyl",
          "id": 3,
          "tags": [
            "CAPA"
          ],
          "timeOfUpload": "Tue, 15 May 2024 13:43:34 GMT"
      },
      {
          "authorFullName": "Policy",
          "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
          "documentTitle": "SOP_Nonconformity.pdf",
          "fullTitle": "Ehegatten",
          "id": 4,
          "tags": [
              "NC"
          ],
          "timeOfUpload": "Tue, 15 May 2024 13:43:59 GMT"
      },
      {
        "authorFullName": "Reference",
        "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
        "documentTitle": "MDR_REGULATION (EU) 2017_745.pdf​",
        "fullTitle": "bsi_standard_200.pdf",
        "id": 5,
        "tags": [
          "Medical device"
        ],
        "timeOfUpload": "Fri, 07 June 2024 13:31:16 GMT"
    },
    ]
const testDataComplianceBefore = [       
        {
            "authorFullName": "Policy",
            "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
            "documentTitle": "SOP_Field_Corrective_Actions.pdf",
            "fullTitle": "ICT_risk_management_tools.pdf",
            "id": 1,
            "tags": [
              "FCA",
              "Assessment",
              "Execution"
            ],
            "timeOfUpload": "Tue, 15 May 2024 13:32:31 GMT"
        },
        {
            "authorFullName": "Policy",
            "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
            "documentTitle": "SOP02_Field_Corrective_Actions.pdf",
            "fullTitle": "Übersicht",
            "id": 2,
            "tags": [
              "FCA",
              "Assessment",
              "Execution"
            ],
            "timeOfUpload": "Tue, 15 May 2024 13:43:16 GMT"
        },
        {
            "authorFullName": "Policy",
            "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
            "documentTitle": "SOP_Corrective_and_Preventive_Action.pdf",
            "fullTitle": "Migration und Asyl",
            "id": 3,
            "tags": [
              "CAPA"
            ],
            "timeOfUpload": "Tue, 15 May 2024 13:43:34 GMT"
        },
        {
            "authorFullName": "Policy",
            "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
            "documentTitle": "SOP_Nonconformity.pdf",
            "fullTitle": "Ehegatten",
            "id": 4,
            "tags": [
                "NC"
            ],
            "timeOfUpload": "Tue, 15 May 2024 13:43:59 GMT"
        },
     
    ]
const newRow = {
  authorFullName: "Reference",
  citableDate: "Mon, 01 Jan 0001 00:00:00 GMT",
  documentTitle: "MDR_REGULATION (EU) 2017_745.pdf​",
  fullTitle: "bsi_standard_200.pdf",
  id: 1,
  tags: ["Medical device"],
  timeOfUpload: "Fri, 07 June 2024 13:31:16 GMT"
};

const testDataFinal = testDataCompliance
    
function DocumentManagement() {
  const maxFileSize = 19;
  const navigate = useNavigate();
  // Content of document list
  const [listContent, setListContent] = useState(null);

  // Upload handling
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState(null);
  const [uploadSuccessfull, setUploadSuccessfull] = useState(null);
  const [currentItemUpload, setCurrentItemUpload] = useState({});
  const [isDocMetaDialogOpen, setDocMetaDialogOpen] = useState(false);

  // constants for handling the different document Actions
  const [showPDF, setShowPDF] = useState(); // VIEW: showPDF is set to the item_id of the currently shown item when one is selected, and set to false when none is shown
  const [urlPDF, setUrlPDF] = useState(); // VIEW: stores link for currently displayed PDF
  const [pdfViewerDisplay, setPdfViewerDisplay] = useState('none');
  const [currentItemDelete, setCurrentItemDelete] = useState(); // DELETE: currentItemDelete is set to the item_id and name of the item that is selected for deletion, or to false when none is selected
  const [editPopupOpen, setEditPopupOpen] = useState(false);  // EDIT
  const [editedDocument, setEditedDocument] = useState(null); // EDIT  
  const [error, setError] = useState(null);

  const [progress, setProgress] = React.useState(10);

  const [isSave, setIsSave] = useState(false);
  const [testDataFinal, setTestDataFinal] = useState(testDataComplianceBefore);
   // Hide and show the extracted result
  const [isVisible, setIsVisible] = useState(false);

 

  const toggleVisibility = () => {
     setIsVisible(!isVisible);
   };
  
  
  
  const handleSave = () => {
    // Navigate to the other page
    // navigate('/initComparison');

    setIsSave(true);
    
    setTimeout(() => {
      if (isSave) {
        setTestDataFinal(testDataFinal => testDataCompliance);
      } else {
        setTestDataFinal(testDataFinal => testDataCompliance);
      }
      setIsVisible(!isVisible);
    
    }, 500); // 0.5 seconds delay
};

  

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    handleClickListDocuments();
  }, []);

  const handleClickEdit = (editedDocument) => {
    editedDocument.name = editedDocument.documentTitle;
    setEditedDocument(editedDocument);
    setEditPopupOpen(true);
  };

  const handleClickPushEdit = () => {
    api.post("/updateDocument", { body: editedDocument })
      .then(() => {
        handleClickListDocuments();
      }).catch((ex) => {
        setError("Failed to update document");
      });
    setEditPopupOpen(false);
  }

  // Handle click to update list of documents
  const handleClickListDocuments = () => {
    setIsUploadLoading(true)
    api.get("/listDocuments").then((response) => {
      let document = response.data;
      setListContent(document)
      setIsUploadLoading(false)
      setUploadMessage(`Updated list.`)
      setUploadSuccessfull("success")
    }).catch((exeption) => {
      console.log(exeption)
      if (exeption.response?.status === 401) {
        navigate('/login', {
          state: {
            error: "Session expired, please login"
          }
        });
      } else {
        setUploadMessage(`Internal Application Error. Please try again later.`)
        setUploadSuccessfull("error")
      }
      setIsUploadLoading(false)
    });
  };

  // Handle click of "Delete" button
  const handleClickDelete = (event, id, name, uuid) => {
    setCurrentItemDelete({
      id: id,
      name: name,
      uuid: uuid
    })

  };

  // Handle click of confirmation in the "delete" confirmation window
  const handleClickConfirmDelete = (event) => {
    setCurrentItemDelete()
    setIsUploadLoading(true)
    console.log(currentItemDelete);
    api.delete(
      "/deleteItem/" + "uuid=" + currentItemDelete.uuid
    ).then((response) => {
      console.log("Delete call response:")
      console.log(response.data)
      setIsUploadLoading(false)
      setUploadMessage(`Successfully deleted file ${currentItemDelete.name}`)
      setUploadSuccessfull("success")
      handleClickListDocuments();
    }).catch((exeption) => {
      console.log(exeption)
      setUploadMessage(`Internal Application Error. Please try again later.`)
      setUploadSuccessfull("error")
      setIsUploadLoading(false)
    });
  };

  const handleClickCancelDelete = (e) => {
    setCurrentItemDelete()
  }

  // Handle click of confirmation in the "Upload" confirmation window
  const handleClickConfirmUpload = (event, file) => {
    setDocMetaDialogOpen(false);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('jsonData', JSON.stringify(currentItemUpload));
    setIsUploadLoading(true)
    api.post('/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }).then((response) => {
      setIsUploadLoading(false)
      setUploadMessage(`Successfully uploaded file ${file.name}`)
      setUploadSuccessfull('success')
      handleClickListDocuments();
      toggleVisibility(); // Hide and show the extracted result
      setTestDataFinal(testDataCompliance)
    }).catch((exeption) => {
      console.log(exeption)
      setIsUploadLoading(false)
      setUploadMessage("Error uploading file.")
      setUploadSuccessfull("error")
    });
  };

  const handleClickCancelUpload = (e) => {
    setDocMetaDialogOpen(false)
    setCurrentItemUpload({})
  }

  const handleClickView = (e, item_id, item_name, item_uuid) => {
    console.log("handleClickView for " + item_id)

    // if the selected item is currently shown, hide it
    // if a new item is selected, switch to that one
    if (item_id === showPDF) {
      setShowPDF(false)
      return false
    }
    else {
      setShowPDF(item_id)
      api.post("/getPdfUrl?" + "id=" + item_id + "&name=" + item_name + "&uuid=" + item_uuid).then((response) => {
        let newList = listContent
        // Update table to store S3 URL in front-end so we don't need to call the API each time we want to show a PDF
        // TODO: actually use the table, only retrieve URL from back-end if it isn't stored in the listContent state yet
        for (const [key, value] of Object.entries(newList)) {
          if (value.id === item_id) {
            let newUrl = response.data
            newList[key].pdfUrl = newUrl

            console.log(newList[key])
            setUrlPDF(newUrl)
          }
        }
        console.log("Did not find ID in list.")
        // return false    
      }).catch((exeption) => {
        console.log(exeption)
        setUploadMessage(`Internal Application Error when attempting to view a document. Please try again later.`)
      });
    }
  }

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      if (file.size / (1024 * 1024) > maxFileSize) {
        setError("File is too big, maximum allowed is 20MB");
      }
      else {
        setCurrentItemUpload({
          name: file.name,
          file: file,
          fullTitle: file.name,
          tags: []
        })
        setDocMetaDialogOpen(true);
      }
    });
  }, []
  )

 
  // Definition of document management table structure
  const columns = [
    {
      field: "id",
      renderHeader: () => (
        <strong>
          {'ID'}
        </strong>
      ),
      flex: 0.5,
    },
    {
      field: "documentTitle",
      renderHeader: () => (
        <strong>
          {'Document Name'}
        </strong>
      ),
      headerAlign: "center",
      minWidth: 150,
      flex: 2.5,
    },
    // {
    //   field: "fullTitle",
    //   renderHeader: () => (
    //     <strong>
    //       {'Title'}
    //     </strong>
    //   ),
    //   type: "string",
    //   headerAlign: "center",
    //   align: "center"
    // },
    {
      field: "authorFullName",
      renderHeader: () => (
        <strong>
          {'Type'}
        </strong>
      ),
      type: "string",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    // {
    //   field: "citableDate",
    //   renderHeader: () => (
    //     <strong>
    //       {'Date (Citations)'}
    //     </strong>
    //   ),
    //   type: "string",
    //   headerAlign: "center",
    //   align: "center"
    // },
    {
      field: "tags",
      renderHeader: () => (
        <strong>
          {'Tags'}
        </strong>
      ),
      editable: false,
      minWidth: 200,
      flex: 2.5,
      renderCell: (params) => {
        const tagChips = params.value.map((tag, index) => (
          <Chip key={index} label={tag} style={{ marginRight: '5px' }} />
        ));
        return (
          <div>
            {tagChips}
          </div>
        );
      },
      headerAlign: "center"
    },
    {
      field: "timeOfUpload",
      renderHeader: () => (
        <strong>
          {"Time of Upload"}
        </strong>
      ),
      type: "string",
      headerAlign: "center",
      align: "center",
      flex: 2,
    },
    {
      field: "actions",
      renderHeader: () => (
        <strong>
          {'Actions'}
        </strong>
      ),
      editable: true,
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        const item_id = params.row?.id
        const item_name = params.row?.documentTitle
        const item_uuid = params.row?.uuid
        return (
          <div>
            <CustomToolTip title="Delete" placement="bottom">
              <IconButton size="100px" onClick={(e) => handleClickDelete(e, item_id, item_name, item_uuid)}>
                <DeleteIcon />
              </IconButton>
            </CustomToolTip>
            {/* <IconButton>
              <DownloadIcon />
            </IconButton> */}
            <CustomToolTip title="View" placement="bottom">
              <IconButton sx={{ color: (showPDF === params.row.id ? 'primary.main' : 'grey') }} onClick={(e) => handleClickView(e, item_id, item_name, item_uuid)}>
                <VisibilityIcon />
              </IconButton>
            </CustomToolTip>
            {/* <CustomToolTip title="Edit" placement="bottom">
              <IconButton>
                <EditIcon onClick={(e) => handleClickEdit(params.row)} />
              </IconButton>            
            </CustomToolTip> */}
          </div>
        );
      },
      headerAlign: "center",
      align: "center"
    }
  ];




  return (
    <div>
      
      {/* Backdrop when document upload process is ongoing */}
      <Backdrop
        className="Doc-Management-Backdrop"
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isUploadLoading}
      >
        <div direction="column">
          <CircularProgress color="inherit" />
          <Typography>Loading</Typography>
        </div>
      </Backdrop>

      {/* Page Content */}
      <Box sx={{ width: '100%', overflowX: 'auto' }}>
      <Stack
        className="Doc-Management-Stack-Row"
        direction="row"
        spacing={1}>

        <Box sx={{ width: '70%', overflowX: 'auto' }}>
          <Stack
            className="Doc-Management-Stack-Column"
            direction="column"
            spacing={2}
            >

            <Dropzone onDrop={onDrop} accept={"image/*"} />

            {uploadSuccessfull && <Alert severity={uploadSuccessfull} mt={2}>{uploadMessage}</Alert>}

              <Box component="span"
                className="Doc-Management-Box-Table"
                sx={{ width: '100%', overflowX: 'auto' }}
                > 
                <DataGrid
                  rows={testDataFinal ? (testDataFinal) : ({})} // if no listContent was loaded yet: display "No rows"
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 10 }
                    }
                  }}
                  pageSizeOptions={[5, 10, 15]}
                  rowSelection={false}
                  sx = {{
                    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {display: 'none'} }}
                />
              </Box>
              <Button
                className="Doc-Management-Button-Update"
                sx={{
                  iconSizeMedium: { "& > *:first-child": { fontSize: 22 } }
                }}
                variant="outlined"
                startIcon={<RefreshIcon />}
                onClick={(e) => handleClickListDocuments(e)} >
                Update Table
              </Button>
          </Stack>
          {/* </div> */}
        </Box>
        {/* Right Side: PDF Viewer */}
        
        {/* Mock-Up: Detail View during Upload */}
        {isVisible && (
        <Box className="detailViewUpload" sx={{ width: '70vb' }}> 
          <Stack>
            <Paper sx={{ width: '100%', height: '700px'}}>
                  <Box sx={{ margin: '20px' }}>
                    {/* <Typography>
                        <b>Uploading document </b>Intern_4.pdf <br />
                        <b>Type: </b>Policy
                      </Typography>
                    <LinearProgressWithLabel value={progress} />
                    <Typography>
                      <b>Status: </b> 20/100 pages processed
                    </Typography> */}
                    <Typography>
                      <b>Document: </b> MDR_REGULATION (EU) 2017_745.pdf<br/>
                    </Typography>
                    <Typography>
                      <b>Type: </b> Reference<br/>
                    </Typography>
                    <Typography>
                      <b>Pages: </b> 175<br/>
                    </Typography>
                    <Typography>
                      <b>Number of Requirements: </b>1244<br/>
                    </Typography>

                    <Divider sx={{ marginTop: '30px', marginBottom: '30px'}}>
                      
                    </Divider>
                    <Typography>
                      <b>Extracted Requirements</b>
                    </Typography>
                    <Box marginBottom="200px">
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ArrowDropDownIcon />}
                        > 
                          <Typography> <b style={{ color: "#43B02A" }}>EUMDR17-A89-1a</b>: <span style={{ color: "black" }}> ‘Serious Incident: Investigation’</span></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <b>Page: </b> 116 <br />
                            <b>Lines: </b> 33 - 35 <br/>
                            The manufacturer shall, without delay, perform the necessary investigations in relation to the serious incident and the devices concerned. This shall include a risk assessment of the incident and field safety corrective action taking into account criteria as referred to in paragraph 3 of this Article as appropriate.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ArrowDropDownIcon />}
                          id="panel2-header"
                        >
                          <Typography>  <b style={{ color: "#43B02A" }}>EUMDR17-A89-1b</b>: <span style={{ color: "black" }}> ’Serious Incident: Co-operation’</span></Typography>
                      
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <b>Page: </b> 116 <br />
                            <b>Lines: </b> 36 - 37 <br/>
                            The manufacturer shall co-operate with the competent authorities and where relevant with the notified body concerned during the investigations referred to in EUMDR17-A89-1a.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    
                      <Accordion >
                        <AccordionSummary
                          expandIcon={<ArrowDropDownIcon />}
                          id="panel2-header"
                        >
                          <Typography> <b style={{ color: "#43B02A" }}>EUMDR17-A89-1c</b>: <span style={{ color: "black" }}>  ’Serious Incident: Evidence’</span></Typography>
                  
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            <b>Page: </b> 116 <br />
                            <b>Lines: </b> 38 <br/>
                            The manufacturer shall, without delay, perform the necessary investigations in relation to the serious incident and the devices concerned. This The manufacturer shall not perform any investigation which involves altering the device or a sample of the batch concerned in a way which may affect any subsequent evaluation of the causes of the incident, prior to informing the competent authorities of such action.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                
                {/* Original button */}
                {/* <Button
                  className="Doc-Management-Button-Update"
                  color="error"
                  sx={{
                    iconSizeMedium: { "& > *:first-child": { fontSize: 22 },
                    marginTop: "100px" }
                  }}
                  variant="outlined"
                  startIcon={<RefreshIcon />}
                  onClick={(e) => handleClickListDocuments(e)} >
                  Cancel Upload
                </Button> */}
                <Stack
                  className="Doc-Comparison-Buttons-Stack-Row"
                  direction="row"
                  spacing={1}>
                    <Button
                      className="Doc-Management-Button-Update"
                      sx={{
                        iconSizeMedium: { "& > *:first-child": { fontSize: 22 } }
                      }}
                      variant="outlined"
                      startIcon={<SaveIcon  />}
                      onClick={handleSave}
                      >
                      Save
                    </Button>
            
                    <Box sx={{display: 'flex', justifyContent: 'right'}}>
                        <Button
                        className="Doc-Management-Button-Update"
                        color="error"
                        sx={{
                          iconSizeMedium: { "& > *:first-child": { fontSize: 22 },
                          marginTop: "100px" }
                        }}
                        variant="outlined"
                        startIcon={<RefreshIcon />}
                      >
                        Cancel Upload
                      </Button>
                    </Box>

                </Stack>
              </Box>
              
            </Paper>
          </Stack>
       
        </Box>
        )}
        {/* Mock-Up: Detail View of uploaded document */}
        {/* <Box className="detailViewUpload" sx={{ width: '70vb' }}>
          <Stack>
            <Paper sx={{ width: '100%', height: '850px'}}>
              <Box sx={{ margin: '20px' }}>
                <Typography>
                    <b>Document: </b>DORA_1.pdf <br />
                    <b>Type: </b>Reference <br/>
                    <b>Pages: </b> 80 pages <br/>
                    <b>Number of Regulations: </b> 100 <br/>
                </Typography>
                <Divider sx={{ marginTop: '30px', marginBottom: '30px'}}>
                  
                </Divider>
                <Typography>
                  <b>Extracted Regulations</b>
                </Typography>
                <Box marginBottom="40px">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                  >
                    <Typography>Regulation 1</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <b>Page: </b> 3 <br />
                      <b>Lines: </b> 13 - 25 <br/>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                      malesuada lacus ex, sit amet blandit leo lobortis eget.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    id="panel2-header"
                  >
                    <Typography>Regulation 2</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <b>Page: </b> 3 <br />
                      <b>Lines: </b> 13 - 25 <br/>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                      malesuada lacus ex, sit amet blandit leo lobortis eget.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                
                  <Accordion >
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                      id="panel2-header"
                    >
                      <Typography>Regulation 3</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <b>Page: </b> 3 <br />
                        <b>Lines: </b> 13 - 25 <br/>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                        malesuada lacus ex, sit amet blandit leo lobortis eget.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>

                <CustomToolTip title="Edit extracted information" placement="bottom">
                  <IconButton>
                    <EditIcon />
                  </IconButton>            
                </CustomToolTip>
              </Box>
              
            </Paper>
          </Stack>
        </Box> */}

        {/* </Box> */}
        <div style={{ position: "absolute", left: "50%", width:"100%" }}>
          {editPopupOpen && <UploadWithAttributes
            handleClickCancelUpload={() => setEditPopupOpen(false)}
            handleClickConfirmUpload={handleClickPushEdit}
            document={editedDocument}
            setDocument={setEditedDocument}
            formTitle="Edit Document"
            buttonText="Save"
          />}
          {/* Delete Confirmation Dialogue */}
          {currentItemDelete && <DeleteConfirmation handleClickCancelDelete={handleClickCancelDelete} handleClickConfirmDelete={handleClickConfirmDelete} currentItemDelete={currentItemDelete} confirmationInformation={"Selected file: " + currentItemDelete.name} />}
          {/* Upload with Attributes Dialogue */}
          {isDocMetaDialogOpen && <UploadWithAttributes
            handleClickCancelUpload={handleClickCancelUpload}
            handleClickConfirmUpload={handleClickConfirmUpload}
            document={currentItemUpload}
            setDocument={setCurrentItemUpload}
          />}
        </div>
        {error && <ErrorBanner message={error} errorSetter={setError} />}
        {showPDF &&
          <Box className="pdfViewer">
            <PdfViewer pdfUrl={urlPDF} selectedPage={1} setError={setError} setPdfViewerDisplay={setPdfViewerDisplay} setSelectedPdfUrl={setUrlPDF} />
          </Box>}
      </Stack>
    <Box/>
  </Box>
  </div>

  )
}

export default DocumentManagement;
