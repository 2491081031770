import React from "react";
import { Box, Button, Divider, Paper, Typography } from "@mui/material";

function ResetConfirmation({ handleClickReset, handleClickConfirmReset, handleClickCancelReset, confirmationText="Do you really want to reset your history?", confirmationHeader="Reset History", clickLabelConfirm="Confirm", clickLabelCancel="Cancel" }) {
    return (
        <div>
            <div className="dialogue-backdrop"/>
            <Paper elevation={3} className="reset-confirmation">
                <Box sx={{ m: 2 }}>
                    <Typography gutterBottom variant="h5" padding="10px">
                    <Divider>{confirmationHeader}</Divider>
                    </Typography>
                </Box>
                <Divider variant="middle" />

                <Box sx={{ m: 2 }}>
                    <Typography gutterBottom variant="body1" align='left'>
                    {confirmationText}
                    </Typography>
                </Box>

                <Box sx={{ mt: 3, ml: 1, mb: 1 }} paddingBottom="10px" align='left'>
                    <Button size="large" style={{ color: "grey" }} onClick={(e) => handleClickCancelReset(e)}>{clickLabelCancel}</Button>
                    <Button size="large" color="secondary" onClick={(e) => handleClickConfirmReset(e)} >
                    {clickLabelConfirm}
                    </Button>
                </Box>
            </Paper>
        </div>
    )
}

export default ResetConfirmation;