import React from "react";
import { Box, Typography } from "@mui/material";
import * as d3 from "d3";

// const testdata_boxplot = [
//     { name: "A", value: 10.7577 },
//     { name: "A", value: 19.9273 },
//     { name: "B", value: 13.8917 },
//     { name: "B", value: 0.5102 },
//     { name: "C", value: 10.5524 },
// ]

const getSummaryStats = (data) => {
    const sortedData = data.sort(function(a, b){return a - b});
    console.log("Sorted Data: ")
    console.log(sortedData)

    const q1 = d3.quantile(sortedData, .25)
    const median = d3.quantile(sortedData, .5)
    const q3 = d3.quantile(sortedData, .75)
  
    console.log(q1)
    console.log(median)
    console.log(q3)

    if(!q3 || !q1 || !median){
        return
    }
  
    const interQuantileRange = q3 - q1
    const min = q1 - 1.5 * interQuantileRange
    const max = q3 + 1.5 * interQuantileRange
  
    return {min, q1, median, q3, max}
}

const VerticalBox = ({
    min,
    q1,
    median,
    q3,
    max,
    width,
    stroke,
    fill,
  }) => {
    const STROKE_WIDTH = 10

    console.log(min,
        q1,
        median,
        q3,
        max,
        width,
        stroke,
        fill,)

    return (        
      <>
        <line
          x1={100 / 2}
          x2={100 / 2}
          y1={5.543450000000001 }
          y2={18.90065 }
          stroke={'black'}
          width={10}
        />
        <rect
          x={0}
          y={q3}
          width={width}
          height={q1 - q3}
          stroke={stroke}
          fill={fill}
        />
        <line
          x1={0}
          x2={width}
          y1={median}
          y2={median}
          stroke={stroke}
          width={STROKE_WIDTH}
        />
      </>
    );
  };

function Boxplot({data}) {
    const summaryStats = getSummaryStats(data);
    console.log(summaryStats)

    if (!summaryStats) { // handle the case where summaryStats is undefined
        return <div>Loading...</div>; 
    }

    const min = summaryStats.min
    const max = summaryStats.max
    const q1 = summaryStats.q1
    const q3 = summaryStats.q3
    const median = summaryStats.median

    return (
        <VerticalBox 
        width={100}
        min={min}
        q1={q1}
        median={median}
        q3={q3}
        max={max}
        stroke="black"
        fill={"#ead4f5"}
        />
    )    
}

export default Boxplot;