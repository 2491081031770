import Mic from "@mui/icons-material/Mic";
import MicOff from "@mui/icons-material/MicOff";
import SendIcon from "@mui/icons-material/Send";
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Divider, IconButton, InputBase, Paper, Stack } from "@mui/material";
import SettingsDialog from "../settings/SettingsDialog";
import SettingsIcon from '@mui/icons-material/Settings';
import { config } from "../../config/config";
import styled from "@emotion/styled";

const CustomToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      // color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 20,
    },
  }));

export function renderQueryComponent(listening, handleMicOff,
    handleMicOn, handleOpenSettings, resetTranscript,
    transcript, isSettingsDialogOpen, handleChangeLanguage,
    selectedLanguageSTT, handleCloseSettingsDialog, llm, handleChangeLLM, 
    query, setQuery, handleQuery, handleResetHistory) {
    const llms = config.LLMS
    return (
            <Paper
                // component="form"
                elevation={4}
                className="query-bar-container"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: "center",
                    width: "80%",
                    minHeight: 70,
                    borderRadius: 18,
                    padding: "15px"
                }}
            >

                {/* Query input */}
                <InputBase
                    sx={{ ml: 1, flex: 1, padding: "20px" }}
                    className="query-bar-input"
                    multiline
                    placeholder="Please enter search query"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    inputProps={{ "aria-label": "Query LLM" }}
                />

                {/* Transcript input */}
                    <InputBase
                    sx={{ ml: 1, flex: 1, padding: "20px" }}
                    className="transcript-bar-input"
                    multiline
                    disabled
                    value={transcript}
                    inputProps={{ "aria-label": "Transcript", style: {textAlign: "right"} }}
                />

                <CustomToolTip title= {listening ? "Mute" : "Unmute"} placement="top">
                    <IconButton sx={{ p: "10px" }} aria-label="menu" onClick={listening ? handleMicOff : handleMicOn}>
                        {listening ? <Mic color="primary" /> : <MicOff color="primary" />}
                    </IconButton>
                </CustomToolTip>

                <CustomToolTip title="Reset Transcript" placement="top">
                    <IconButton sx={{ p: "10px" }} aria-label="menu" onClick={resetTranscript}>
                       <DeleteOutlineIcon color="primary" />
                    </IconButton>
                </CustomToolTip>

                {/* Divider*/}
                <Divider sx={{ height: 28, m: 0.5 }} className="query-bar-divider" orientation="vertical" />

                {/* Search/Query Button */}
                <CustomToolTip title="Query LLM" placement="top">
                    <IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={(e) => handleQuery(e, query, llm)} >
                        <SendIcon color="primary" />
                    </IconButton>
                </CustomToolTip>

                <CustomToolTip title="Reset Chat History" placement="top">
                <IconButton size="100px" onClick={(e) => handleResetHistory(e)} color="primary">
                        <RefreshIcon color="primary"/>
                </IconButton>
                </CustomToolTip>

                {/* Divider
                <Divider sx={{ height: 28, m: 0.5 }} className="query-bar-divider" orientation="vertical" /> */}

                <CustomToolTip title="Settings" placement="top">
                <IconButton onClick={handleOpenSettings}>
                    <SettingsIcon color="primary" />
                </IconButton>
                </CustomToolTip>
                <SettingsDialog
                    open={isSettingsDialogOpen}
                    handleChange={handleChangeLanguage}
                    language={selectedLanguageSTT}
                    handleClose={handleCloseSettingsDialog}
                    llms={llms}
                    llm={llm}
                    handleChangeLLM={handleChangeLLM}
                />
            </Paper>
    )
}