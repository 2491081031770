import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Alert, Backdrop, Box, Button, CircularProgress, Divider, Stack, styled, Typography } from '@mui/material';
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import { DataGrid } from "@mui/x-data-grid";
import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import api from "../config/axiosConfigs";
import Dropzone from '../features/dropzone/Dropzone';
import './DocumentManagement.css';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import UploadWithAttributes from "../features/attributeManagement/UploadWithAttributes";
import DeleteConfirmation from "../features/deleteConfirmation/DeleteConfirmation";
import ErrorBanner from '../features/errorBanner/errorBanner';
import { PdfViewer } from "../features/PdfViewer";
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const testDataPolicies = [
  {
      "authorFullName": "Policy",
      "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
      "documentTitle": "SOP_Field_Corrective_Actions.pdf",
      "policy": "SOP_PCA_5.3.1.a",
      "page":"3",
      "lines":"13 - 25",
      "id": 2,
      "tags": [
        "Büromaterial"
      ],
      "timeOfUpload": "Thu, 11 Jan 2024 13:32:31 GMT",
      "detailText": "Regional FCA Lead(s) or delegate notify(ies) Competent Authority in affected countries, as required by local laws/requirements, prior to some or all documents described below are being finalized to ensure reporting deadlines are met Follow Annex to SOP Assessment and Execution of Field Corrective Action Regional FCA Lead(s) align(s) with	FCA Owner and	FCA GPO on communication to Competent Authorities  …​"
      },
  {
      "authorFullName": "Policy",
      "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
      "documentTitle": "SOP_Field_Corrective_Actions.pdf",
      "policy": "SOP_PCA_5.3.1.b.2",
      "page":"6",
      "lines":"6 - 44",
      "id": 3,
      "tags": [
        "Büromaterial"
      ],
      "timeOfUpload": "Thu, 11 Jan 2024 13:43:16 GMT",
      "detailText": "Identifies details of action including: Product details (e.g. product batches, lots, UDI affected, product status) Product whereabouts (e.g. on premise, at the warehouses, distributor warehouses or at the end customer)"
  },
  {
      "authorFullName": "Policy",
      "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
      "documentTitle": "SOP_Field_Corrective_Actions.pdf",
      "policy": "SOP_PCA_5.3.1.c",
      "page":"10",
      "lines":"22 - 51",
      "id": 7,
      "tags": [
        "Büromaterial",
        "IT"
      ],
      "timeOfUpload": "Thu, 11 Jan 2024 13:44:46 GMT",
      "detailText": "FCA Owner prepares the following documents and reviews them with internal subject matter experts: Quality information sheet to provide guidance for FCA implementation per affected region Mandatory reviewers:	FCA GPO, Legal FSN for user/care provider communication Mandatory reviewers: Legal, Marketing, Medical Safety, Communications "
  },

]

const testDataRegulations = [
  {
      "authorFullName": "Reference",
      "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
      "documentTitle": "MDR_ REGULATION (EU) 2017_745.pdf",
      "fullTitle": "bsi_standard_200.pdf",
      "requirement":"Serious Incident: Final Report",
      "id": "EUMDR17-​A89-5",
      "page":"75",
      "lines":"13 - 15",
      "tags": [
        "IT"
      ],
      "timeOfUpload": "Thu, 11 Jan 2024 13:31:16 GMT",
      "detailText": "The manufacturer shall provide a final report to the competent authority setting out its findings from the investigation by means of the electronic system referred to in Article 92. The report shall set out conclusions and where relevant indicate corrective actions to be taken."
  },
  {
      "authorFullName": "Reference",
      "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
      "documentTitle": "MDR_ REGULATION (EU) 2017_745.pdf",
      "fullTitle": "Migration und Asyl",
      "requirement":"FCA: Field Safety Notice",
      "id": "EUMDR17-​A89-6a",
      "page":"75",
      "lines":"31 - 32",
      "tags": [
        "Büromaterial"
      ],
      "timeOfUpload": "Thu, 11 Jan 2024 13:43:34 GMT",
      "detailText": "The manufacturer shall ensure that information about the field safety corrective action taken is brought without delay to the attention of users of the device in question by means of a field safety notice."
  },
  {
      "authorFullName": "Reference",
      "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
      "documentTitle": "MDR_ REGULATION (EU) 2017_745.pdf",
      "fullTitle": "Ehegatten",
      "requirement":"FCA: Language",
      "id": "EUMDR17-​A89-6b",
      "page":"75",
      "lines":"32 - 34",
      "tags": [
          "IT"
      ],
      "timeOfUpload": "Thu, 11 Jan 2024 13:43:59 GMT",
      "detailText": "The field safety notice shall be edited in an official Union language or languages determined"
  },
  
]

const CustomToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      boxShadow: theme.shadows[1],
      fontSize: 20,
  },
}));

function InitComparison() {
  const maxFileSize = 19;
  const navigate = useNavigate();
  // Content of document list
  const [listContent, setListContent] = useState(null); // needs a duplicate for policy and reference regulation each

  // Interaction status handling
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState(null);
  const [uploadSuccessfull, setUploadSuccessfull] = useState(null);

  // constants for handling the different document Actions
  const [currentItemDelete, setCurrentItemDelete] = useState(); // reuse this for compare button; DELETE: currentItemDelete is set to the item_id and name of the item that is selected for deletion, or to false when none is selected
  const [error, setError] = useState(null);

  useEffect(() => {
    handleClickListDocuments();
  }, []);

  // Handle click to update list of documents
  const handleClickListDocuments = () => {
    setIsUploadLoading(true)
    api.get("/listDocuments").then((response) => {
      let document = response.data;
      setListContent(document)
      setIsUploadLoading(false)
      setUploadMessage(`Updated list.`)
      setUploadSuccessfull("success")
    }).catch((exeption) => {
      console.log(exeption)
      if (exeption.response?.status === 401) {
        navigate('/login', {
          state: {
            error: "Session expired, please login"
          }
        });
      } else {
        setUploadMessage(`Internal Application Error. Please try again later.`)
        setUploadSuccessfull("error")
      }
      setIsUploadLoading(false)
    });
  };

  // Handle click of "Delete" button
  const handleClickDelete = (event, id, name, uuid) => {
    setCurrentItemDelete({
      id: id,
      name: name,
      uuid: uuid
    })

  };

  // Handle click of confirmation in the "delete" confirmation window
  const handleClickConfirmDelete = (event) => {
    setCurrentItemDelete()
    setIsUploadLoading(true)
    console.log(currentItemDelete);
    api.delete(
      "/deleteItem/" + "uuid=" + currentItemDelete.uuid
    ).then((response) => {
      console.log("Delete call response:")
      console.log(response.data)
      setIsUploadLoading(false)
      setUploadMessage(`Successfully deleted file ${currentItemDelete.name}`)
      setUploadSuccessfull("success")
      handleClickListDocuments();
    }).catch((exeption) => {
      console.log(exeption)
      setUploadMessage(`Internal Application Error. Please try again later.`)
      setUploadSuccessfull("error")
      setIsUploadLoading(false)
    });
  };

  const handleClickCancelDelete = (e) => {
    setCurrentItemDelete()
  }
  // add for interactive selection
  const [selectedPolicyItems, setSelectedPolicyItems] = useState([]);
  const [displayedPolicyItems, setDisplayedPolicyItems] = useState([]);

  const handlePolicySelectionChange = (newSelection) => {
    setSelectedPolicyItems(newSelection);
  };

  const updatePolicyTable = () => {
    const selectedRows = testDataPolicies.filter((row) =>
      selectedPolicyItems.includes(row.id)
    );
    setDisplayedPolicyItems(selectedRows);
  };

  const [selectedReqItems, setSelectedReqItems] = useState([]);
  const [displayedReqItems, setDisplayedReqItems] = useState([]);

  const handleReqSelectionChange = (newSelection) => {
    setSelectedReqItems(newSelection);
  };

  const updateReqTable = () => {
    const selectedRows = testDataRegulations.filter((row) =>
      selectedReqItems.includes(row.id)
    );
    setDisplayedReqItems(selectedRows);
  };

  const handleUpdateTables = () => {
    updateReqTable();
    updatePolicyTable();
  };
  // Definition of document management table structure
  const requirement_columns = [
    {
      field: "id",
      renderHeader: () => (
        <strong>
          {'ID'}
        </strong>
      ),
    },
    {
      field: "documentTitle",
      renderHeader: () => (
        <strong>
          {'Document Name'}
        </strong>
      ),
      width: 150
    },
    {
      field: "authorFullName",
      renderHeader: () => (
        <strong>
          {'Type'}
        </strong>
      ),
      type: "string",
      headerAlign: "center",
      align: "center",
      width: 100
    },
    {
      field: "requirement",
      renderHeader: () => (
        <strong>
          {"Requirement"}
        </strong>
      ),
      type: "string",
      headerAlign: "center",
      align: "center",
      width: 200
    },
    {
      field: "detailText",
      renderHeader: () => (
        <strong>
          {"Content"}
        </strong>
      ),
      type: "string",
      headerAlign: "center",
      align: "center",
      width: 300
    },
  ];

  const columns = [
    {
      field: "id",
      renderHeader: () => (
        <strong>
          {'ID'}
        </strong>
      ),
    },
    {
      field: "documentTitle",
      renderHeader: () => (
        <strong>
          {'Document Name'}
        </strong>
      ),
      width: 150
    },
    {
      field: "authorFullName",
      renderHeader: () => (
        <strong>
          {'Type'}
        </strong>
      ),
      type: "string",
      headerAlign: "center",
      align: "center"
    },
    {
      field: "detailText",
      renderHeader: () => (
        <strong>
          {"Content"}
        </strong>
      ),
      type: "string",
      headerAlign: "center",
      align: "center",
      width: 300
    },
  ];

  const policycolumns = [
    {
      field: "id",
      renderHeader: () => (
        <strong>
          {'ID'}
        </strong>
      ),
    },
    {
      field: "documentTitle",
      renderHeader: () => (
        <strong>
          {'Document Name'}
        </strong>
      ),
      width: 150
    },
    {
      field: "authorFullName",
      renderHeader: () => (
        <strong>
          {'Type'}
        </strong>
      ),
      type: "string",
      headerAlign: "center",
      align: "center",
      width: 100
    },
    {
      field: "policy",
      renderHeader: () => (
        <strong>
          {'Policy'}
        </strong>
      ),
      type: "string",
      headerAlign: "center",
      align: "center",
      width: 200
    },
    {
      field: "detailText",
      renderHeader: () => (
        <strong>
          {"Content"}
        </strong>
      ),
      type: "string",
      headerAlign: "center",
      align: "center",
      width: 300
    },
  ];

  const navigateToCompDashboard = () => {
    // Navigate to the other page
    navigate('/comparisonDashboard');
  };


  return (
    <div>

      {/* Backdrop when document upload process is ongoing */}
      <Backdrop
        className="Doc-Management-Backdrop"
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isUploadLoading}
      >
        <div direction="column">
          <CircularProgress color="inherit" />
          <Typography>Loading</Typography>
        </div>
      </Backdrop>

      {/* Page Content */}
    
      <Stack
        className="Doc-Management-Stack-Row"
        direction="row"
        spacing={1}>
        
        {/* Left Side: List available regulations and policies */}
        <Box >
          <Stack
            className="Doc-Management-Stack-Column"
            direction="column"
            spacing={2}
          >
            <Typography variant="h6">
              <b>Selection</b>
            </Typography>

            {/* {uploadSuccessfull && <Alert severity={uploadSuccessfull} mt={2}>{uploadMessage}</Alert>} */}
            <Typography>
              <b>Select Policies</b>
            </Typography>
            <Box
              className="Doc-Management-Box-Table" width="900px" height="300px">
              <DataGrid
                rows={testDataPolicies ? (testDataPolicies) : ({})} // if no listContent was loaded yet: display "No rows"
                columns={policycolumns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 3 }
                  }
                }}
                pageSizeOptions={[3, 9, 12, 15]}
                checkboxSelection
                // add new line
                onRowSelectionModelChange={(newSelectionModel) => {
                  handlePolicySelectionChange(newSelectionModel);
                }}
              />
            </Box>
            <Typography>
              <b>Select Requirements</b>
            </Typography>
            <Box 
              className="Doc-Management-Box-Table" width="900px" height="300px">
              <DataGrid
                rows={testDataRegulations ? (testDataRegulations) : ({})} // if no listContent was loaded yet: display "No rows"
                columns={requirement_columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 3 }
                  }
                }}
                pageSizeOptions={[3, 9, 12, 15]}
                checkboxSelection
                onRowSelectionModelChange={(newSelectionModel) => {
                  handleReqSelectionChange(newSelectionModel);
                }}
              />
            </Box>
            
          </Stack>
          {/* </div> */}
        </Box>

        {/* Mock-Up: Overview of what will be compared */}
        <Box className="detailViewUpload" sx={{ width: '70vb' }}>
        <Stack>
          <Paper sx={{ width: '100%', height: '370px', marginBottom: '20px' }}>
            <Typography variant="h6">
              <b>Comparison Selection</b>
            </Typography>
            <Typography>
              <b>Selected Polices</b>
            </Typography>
            <Box sx={{ margin: '20px' , marginBottom: '20px'}}>
              {displayedPolicyItems.map(item => (
                <Box marginBottom="5px" key={item.id}>
                  <Typography>
                    <b>{item.name}</b>
                  </Typography>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                    >
                      <Typography>{item.policy}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <b>ID: </b> {item.id} <br />
                        <b>Page: </b> {item.page} <br />
                        <b>Lines: </b> {item.lines} <br/>
                        {item.detailText}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              ))}
            </Box>
            </Paper>
        
            {/* <Divider sx={{ marginTop: '30px', marginBottom: '30px'}}/> */}
            <Paper sx={{ width: '100%', height: '370px', marginBottom: '20px' }}>
            <Typography>
              <b>Selected Reference Regulations</b>
            </Typography>


            <Box sx={{ margin: '20px' }}>
              {displayedReqItems.map(item => (
                <Box marginBottom="5px" key={item.id}>
                  <Typography>
                    <b>{item.name}</b>
                  </Typography>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ArrowDropDownIcon />}
                    >
                      <Typography>{item.requirement}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <b>ID: </b> {item.id} <br />
                        <b>Page: </b> {item.page} <br />
                        <b>Lines: </b> {item.lines} <br/>
                        {item.detailText}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              ))}
            </Box>
    
          </Paper>
           
            <Stack
              className="Doc-Comparison-Buttons-Stack-Row"
              direction="row"
              spacing={1}>
                 <Button
                className="Doc-Management-Button-Update"
                sx={{
                  iconSizeMedium: { "& > *:first-child": { fontSize: 22 } }
                }}
                variant="outlined"
                startIcon={<RefreshIcon />}
                onClick={handleUpdateTables} >
                Update Tables
              </Button>
          
              <Box sx={{display: 'flex', justifyContent: 'right'}}>
                <Button
                  className="Doc-Management-Button-Update"
                  sx={{
                    iconSizeMedium: { "& > *:first-child": { fontSize: 22 } },
                    backgroundColor: '#43B02A', 
                    color:'white',
                    "&:hover": {
                        // change the color when the button is hovered
                        backgroundColor: 'white',
                        color:'#43B02A',
                    }
                }}
                  variant="outlined"
                  startIcon={<CheckCircleIcon />}
                  // onClick={(e) => handleClickListDocuments(e)} >
                  onClick={navigateToCompDashboard}
                  >
                  Start comparison
                </Button>
              </Box>
            </Stack>

            
          </Stack>
        </Box>


        {/* </Box> */}
        <div style={{ position: "absolute", left: "50%" }}>
          {/* Delete Confirmation Dialogue */}
          {currentItemDelete && <DeleteConfirmation handleClickCancelDelete={handleClickCancelDelete} handleClickConfirmDelete={handleClickConfirmDelete} currentItemDelete={currentItemDelete} confirmationInformation={"Selected file: " + currentItemDelete.name} />}
        </div>
        {error && <ErrorBanner message={error} errorSetter={setError} />}
      </Stack>
    </div>

  )
}

export default InitComparison;
