import { createTheme } from "@mui/material/styles";

const customTheme = createTheme({
    palette: {
        mode: "light",
        primary: {
          main: '#43B02A', // #A9260E
        },
        secondary: {
          main: '#000000',
        },
        bot : {
          background: '#9DD4CF',  // original: primary.light
          font: '#111111' // 000000
        },
        user : {
          background: '#86BC25', // secondary.light
          font: '#EEEEEE' // FFFFFF
        }
      },
    typography: {
        h1: {
            fontSize: "40px"
        },
        h2: {
            fontSize: "30px"
        },
        fontFamily: `Calibri`,
        fontSize: 18,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          h1: {
            '&.MuiTypography-gutterBottom': {
              marginBottom: 32
            }
          },
          gutterBottom: {
            marginBottom: 8 //default e.g. body1/paragraphs
          }
        }
      },
      MuiTextField:{
        styleOverrides: {
          root : {'& label.Mui-focused': {
            color: '#A0AAB4',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#B2BAC2',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#E0E3E7',
            },
            '&:hover fieldset': {
              borderColor: '#B2BAC2',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#6F7E8C',
            },
          }},
        }
      },
      DatePicker: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-notchedOutline' : {
              borderColor: '#E0E3E7',
            },
            '&:hover .MuiOutlinedInput-notchedOutline' : {
              borderColor: '#B2BAC2',
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: 'black',
            },
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#B2BAC2',
            },
          }
        }
      }
    }
})

export default customTheme;
