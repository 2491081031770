import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from '@mui/icons-material/Refresh';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Alert, Backdrop, Box, Button, CircularProgress, Divider, Stack, styled, Typography } from '@mui/material';
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import ChatIcon from '@mui/icons-material/Chat';
import { DataGrid } from "@mui/x-data-grid";
import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import api from "../config/axiosConfigs";
import Dropzone from '../features/dropzone/Dropzone';
import './DocumentManagement.css';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import UploadWithAttributes from "../features/attributeManagement/UploadWithAttributes";
import DeleteConfirmation from "../features/deleteConfirmation/DeleteConfirmation";
import ErrorBanner from '../features/errorBanner/errorBanner';
import { PdfViewer } from "../features/PdfViewer";
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const testDataPolicies = [
  {
      "authorFullName": "Match",
      "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
      "documentTitle": "MDR_ REGULATION (EU) 2017_745.pdf",
      "fullTitle": "ICT_risk_management_tools.pdf",
      "id": "EUMDR17-A89-1a",
      "tags": [
        "Büromaterial"
      ],
      "timeOfUpload": "Thu, 11 Jan 2024 13:32:31 GMT",
      "detailText": "Serious Incident: Investigations"
  },
  {
      "authorFullName": "Match",
      "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
      "documentTitle": "MDR_ REGULATION (EU) 2017_745.pdf",
      "fullTitle": "Übersicht",
      "id": "EUMDR17-A89-1b",
      "tags": [
        "Büromaterial"
      ],
      "timeOfUpload": "Thu, 11 Jan 2024 13:43:16 GMT",
      "detailText": "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."
  },
  {
      "authorFullName": "Match",
      "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
      "documentTitle": "MDR_ REGULATION (EU) 2017_745.pdf",
      "fullTitle": "Heimatlose Ausländer",
      "id": "EUMDR17-A89-1c",
      "tags": [
        "Büromaterial",
        "IT"
      ],
      "timeOfUpload": "Thu, 11 Jan 2024 13:44:46 GMT",
      "detailText": "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."
  },
  {
      "authorFullName": "Match",
      "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
      "documentTitle": "MDR_ REGULATION (EU) 2017_745.pdf",
      "fullTitle": "Seit Geburt Staatenlose",
      "id": "EUMDR17-A89-3",
      "tags": [
          "Büromaterial",
          "IT"
      ],
      "timeOfUpload": "Thu, 11 Jan 2024 13:44:56 GMT",
      "detailText": "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."
  },
  {
    "authorFullName": "Match",
    "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
    "documentTitle": "MDR_ REGULATION (EU) 2017_745.pdf",
    "fullTitle": "ICT_risk_management_tools.pdf",
    "id": "EUMDR17-A89-5",
    "tags": [
      "Büromaterial"
    ],
    "timeOfUpload": "Thu, 11 Jan 2024 13:32:31 GMT",
    "detailText": "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."
},
{
    "authorFullName": "Match",
    "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
    "documentTitle": "MDR_ REGULATION (EU) 2017_745.pdf",
    "fullTitle": "Übersicht",
    "id": "EUMDR17-A89-6a",
    "tags": [
      "Büromaterial"
    ],
    "timeOfUpload": "Thu, 11 Jan 2024 13:43:16 GMT",
    "detailText": "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."
},
{
    "authorFullName": "Match",
    "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
    "documentTitle": "MDR_ REGULATION (EU) 2017_745.pdf",
    "fullTitle": "Heimatlose Ausländer",
    "id": "EUMDR17-A89-6b",
    "tags": [
      "Büromaterial",
      "IT"
    ],
    "timeOfUpload": "Thu, 11 Jan 2024 13:44:46 GMT",
    "detailText": "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."
},
{
    "authorFullName": "Match",
    "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
    "documentTitle": "MDR_ REGULATION (EU) 2017_745.pdf",
    "fullTitle": "Seit Geburt Staatenlose",
    "id": "EUMDR17-A89-6c",
    "tags": [
        "Büromaterial",
        "IT"
    ],
    "timeOfUpload": "Thu, 11 Jan 2024 13:44:56 GMT",
    "detailText": "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."
},
{
  "authorFullName": "Match",
  "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
  "documentTitle": "MDR_ REGULATION (EU) 2017_745.pdf",
  "fullTitle": "Heimatlose Ausländer",
  "id": "EUMDR17-A89-6d",
  "tags": [
    "Büromaterial",
    "IT"
  ],
  "timeOfUpload": "Thu, 11 Jan 2024 13:44:46 GMT",
  "detailText": "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."
},
{
  "authorFullName": "Match",
  "citableDate": "Mon, 01 Jan 0001 00:00:00 GMT",
  "documentTitle": "MDR_ REGULATION (EU) 2017_745.pdf",
  "fullTitle": "Seit Geburt Staatenlose",
  "id": "EUMDR17-A89-6e",
  "tags": [
      "Büromaterial",
      "IT"
  ],
  "timeOfUpload": "Thu, 11 Jan 2024 13:44:56 GMT",
  "detailText": "At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum."
},
]

const CustomToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      boxShadow: theme.shadows[1],
      fontSize: 20,
  },
}));

function EvaluationReport() {
  const maxFileSize = 19;
  const navigate = useNavigate();
  // Content of document list
  const [listContent, setListContent] = useState(null); // needs a duplicate for policy and reference regulation each

  // Interaction status handling
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState(null);
  const [uploadSuccessfull, setUploadSuccessfull] = useState(null);

  // constants for handling the different document Actions
  const [currentItemDelete, setCurrentItemDelete] = useState(); // reuse this for compare button; DELETE: currentItemDelete is set to the item_id and name of the item that is selected for deletion, or to false when none is selected
  const [error, setError] = useState(null);

  useEffect(() => {
    handleClickListDocuments();
  }, []);

  // Handle click to update list of documents
  const handleClickListDocuments = () => {
    setIsUploadLoading(true)
    api.get("/listDocuments").then((response) => {
      let document = response.data;
      setListContent(document)
      setIsUploadLoading(false)
      setUploadMessage(`Updated list.`)
      setUploadSuccessfull("success")
    }).catch((exeption) => {
      console.log(exeption)
      if (exeption.response?.status === 401) {
        navigate('/login', {
          state: {
            error: "Session expired, please login"
          }
        });
      } else {
        setUploadMessage(`Internal Application Error. Please try again later.`)
        setUploadSuccessfull("error")
      }
      setIsUploadLoading(false)
    });
  };

  // Handle click of "Delete" button
  const handleClickDelete = (event, id, name, uuid) => {
    setCurrentItemDelete({
      id: id,
      name: name,
      uuid: uuid
    })

  };

  // Handle click of confirmation in the "delete" confirmation window
  const handleClickConfirmDelete = (event) => {
    setCurrentItemDelete()
    setIsUploadLoading(true)
    console.log(currentItemDelete);
    api.delete(
      "/deleteItem/" + "uuid=" + currentItemDelete.uuid
    ).then((response) => {
      console.log("Delete call response:")
      console.log(response.data)
      setIsUploadLoading(false)
      setUploadMessage(`Successfully deleted file ${currentItemDelete.name}`)
      setUploadSuccessfull("success")
      handleClickListDocuments();
    }).catch((exeption) => {
      console.log(exeption)
      setUploadMessage(`Internal Application Error. Please try again later.`)
      setUploadSuccessfull("error")
      setIsUploadLoading(false)
    });
  };

  const handleClickCancelDelete = (e) => {
    setCurrentItemDelete()
  }


  // Definition of document management table structure
  const columns = [
    {
      field: "id",
      renderHeader: () => (
        <strong>
          {'ID'}
        </strong>
      ),
    },
    {
      field: "detailText",
      renderHeader: () => (
        <strong>
          {"Requirement"}
        </strong>
      ),
      type: "string",
      headerAlign: "center",
      align: "center",
      width: 400
    },
    {
      field: "documentTitle",
      renderHeader: () => (
        <strong>
          {'Source'}
        </strong>
      ),
      width: 150
    },
    {
      field: "authorFullName",
      renderHeader: () => (
        <strong>
          {'Status'}
        </strong>
      ),
      type: "string",
      headerAlign: "center",
      align: "center"
    },
    
  ];

  const handleClickChatbot = () => {
    console.log("Now we should switch to the Chatbot page.")
  }  

  return (
    <div>

      {/* Backdrop when document upload process is ongoing */}
      <Backdrop
        className="Doc-Management-Backdrop"
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isUploadLoading}
      >
        <div direction="column">
          <CircularProgress color="inherit" />
          <Typography>Loading</Typography>
        </div>
      </Backdrop>

      {/* Page Content */}

      <Stack
        className="Doc-Management-Stack-Row"
        direction="row"
        spacing={1}>
        
        {/* Left Side: Evaluation Report Overview */}
        <Box
          className="Doc-Management-Box-Table" width="820px" height="700px">
          <DataGrid
            rows={testDataPolicies ? (testDataPolicies) : ({})} // if no listContent was loaded yet: display "No rows"
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 }
              }
            }}
            pageSizeOptions={[10]}
          />
        </Box>

        {/* Right Side Mock-Up: Detail View - what is the matching text that was found? */}
        <Box className="detailViewUpload" sx={{ width: '70vb' }}>
          <Stack>
            <Paper sx={{ width: '100%', height: '700px', marginBottom: '20px'}}>
              <Typography variant="h6">
                <b>Detail View</b>
              </Typography>
              
              {/* Show Regulation */}
              <Box marginLeft="10px" marginRight="10px">
                <Typography variant="h6">
                  <b>Regulatory Requirement</b>
                </Typography>
                <Typography align="left" variant="body2">
                  <b>ID: </b> 2 (c) <br />
                  <b>Source Document: </b> DORA_1.pdf <br />
                  <b>Status: </b> Match <br />
                At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
                </Typography>
              </Box>

              {/* Show Match */}
              <Box marginLeft="10px" marginRight="10px">
                <Typography variant="h6">
                  <b>Match</b>
                </Typography>
                <Typography align="left" variant="body2">
                <b>Source Document: </b> Intern_1.pdf <i>(p. 13, lines 10-14)</i> <br />
                <b>Confidence Score: </b> 0.93 <br />
                Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.   
                </Typography>
              </Box>

              {/* Show Suggestion */}
              <Box marginLeft="10px" marginRight="10px">
                <Typography variant="h6">
                  <b>Suggested Wording</b>
                </Typography>
                <Typography align="left" variant="body2">
                Stet clita kasd gubergren, <b>at vero eos et accusam et justo duo dolores et ea rebum.</b> No sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.   
                </Typography>
              </Box>
            </Paper>

            <Stack
            className="Doc-Comparison-Buttons-Stack-Row"
            direction="row"
            spacing={1}>
              
                <Button
                    className="Doc-Management-Button-Update"
                    sx={{
                      iconSizeMedium: { "& > *:first-child": { fontSize: 22 } }
                    }}
                    size="small"
                    variant="outlined"
                    startIcon={<EditIcon />}  >
                    Adapt Suggested Text
                  </Button>

                
                <Button
                    className="Doc-Management-Button-Update"
                    sx={{
                      iconSizeMedium: { "& > *:first-child": { fontSize: 22 } }
                    }}
                    size="small"
                    variant="outlined"
                    startIcon={<ContentCopyIcon />}  >
                    Copy to Clipboard
                </Button>
            </Stack>
          </Stack>
        </Box>

        {/* </Box> */}
        <div style={{ position: "absolute", left: "50%" }}>
          {/* Delete Confirmation Dialogue */}
          {currentItemDelete && <DeleteConfirmation handleClickCancelDelete={handleClickCancelDelete} handleClickConfirmDelete={handleClickConfirmDelete} currentItemDelete={currentItemDelete} confirmationInformation={"Selected file: " + currentItemDelete.name} />}
        </div>
        {error && <ErrorBanner message={error} errorSetter={setError} />}
      </Stack>
    </div>
  )
}

export default EvaluationReport;